import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function LeftNav(){

    return (
        <div className="col-md-6 pr-lg-5 mt--80 mb-md-0 text-center d-lg-block d-none" style={{ position:'relative', zIndex:9 }}>
            <img src="../assets/images/uba-logo-white.png" alt="" className="img-fluid3" />
            <div className="container1 mt-15">
                <h1>SMART SELL</h1>
                <p className="all_As mt-5">USSD just got easier with better graphical user interface</p>
                <p className="all_As1 mt-10">
                    <a href="https://www.ubagroup.com/" target="_blank">Visit Main Website</a> | {` `}
                    <a href="https://www.ubagroup.com/uba-privacy-policy/" target="_blank">Privacy & Policy</a> | {` `}
                    <a href="https://www.ubagroup.com/terms-conditions" target="_blank">Terms of Use</a>
                </p>
            </div>
        </div>
    );
}