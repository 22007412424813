import React, { useEffect, useState } from "react";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import ContactUs from "./ContactUs";


export default function AcctOpen(props){
    const { closeHandler, titles, handleCheckboxClick, isLoading, setShowErrMessages, register, errors, handleSubmit, codes, isSubmitting, isValid, setValue, cusDetails, setInputDatas, openDivHandler } = props;
    

    return (
        <div className="mainDiv">
            <div className="all_btns mt-2 text-center">
                <p className="mb-4" style={{ color:'#222', fontWeight:'600', lineHeight:'22px', fontSize:'16px' }}>{titles}</p>

                <button 
                className="btn btn-primary" 
                type="button" 
                onClick={() => window.open("https://www.ubagroup.com/open-a-uba-account-online/", "_blank")}
                >
                    Click to Open Link
                </button>
                
                <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
            </div>

        </div>
    );
}