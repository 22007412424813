import React, { useEffect, useState } from "react";


export default function UserAccounts(props){
    const { register, errors } = props;


    return (
        <div className="enterPin text-start">
            <p className="mt-1 mb-3" style={{ fontSize: '18px', fontWeight: 600 }}>Select Your Account Type</p>

            <div className="row radios1 mt--5 mb-10">
                <div className="col-12 inner_div_scroll pt-5 pb-10">
                    <div key="bank1" className="row" style={{ marginBottom:'13px' }}>
                        <div className="col-2 pr-0">
                            <input 
                                className="mt-2"
                                style={{ position: 'relative', zIndex:9 }} type="radio" value="09948484845||UBA Savings Account" id="bank1" name="user_account" autoComplete="off" 

                                {...register("account_type", { 
                                    required: "Please select a beneficiary",
                                })}
                            /> 
                        </div>
                        <div className="col-10 pl-0">
                            <label className="div_bg div_bg1 div_bg2" htmlFor="bank1">
                                <label className="acct_no" htmlFor="bank1">09948484845</label>
                                <label className="acct_type" htmlFor="bank1">UBA Savings Account</label>
                                <label htmlFor="bank1" className="fonts">N300,000</label>
                            </label>
                        </div>
                    </div>

                    <div key="bank2" className="row mb-2" >
                        <div className="col-2 pr-0">
                            <input 
                                className="mt-2"
                                style={{ position: 'relative', zIndex:9 }} type="radio" value="09948484845||UBA Current Account" id="bank2" name="user_account" autoComplete="off" 
                                {...register("account_type", { 
                                    required: "Please select a beneficiary",
                                })}
                            /> 
                        </div>
                        <div className="col-10 pl-0">
                            <label className="div_bg div_bg1 div_bg2" htmlFor="bank2">
                                <label className="acct_no" htmlFor="bank2">09948484845</label>
                                <label className="acct_type" htmlFor="bank2">UBA Current Account</label>
                                <label htmlFor="bank2" className="fonts">N1,500,000</label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            {errors.account_type && <label className="errors">{errors.account_type?.message}</label>}
        </div>
    );
}