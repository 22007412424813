import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";


export default function MiniStatement(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone, cusDetails, isSuccess1 } = props;

    const [togglePassword, setTogglePassword] = useState(false);
    // const [ntwkChange, setNtwkChange] = useState('Mtn');

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }


    const handleFormActn = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }

        setValue("pin", "");

        if(codes === "mini_statement"){
            if(steps === 1){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(2);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 2){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }



    return (
        <form className="mt--5" onSubmit={handleSubmit(handleFormActn)}>
            
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    (steps === 2) &&
                    <>
                        <div className="text-center pt-10 pb-10">
                            <h4 style={{ fontWeight:'700', fontSize:'16px' }}>
                                Your Mini Statement of Account
                            </h4>
                            {/* <p className="mt-10" style={{ lineHeight:'18px' }}>USSD/PAYATTUSSD NETWORK</p> */}
                            <div className="row mt-15 mb-10">
                                <div className="col-8 text-end">
                                    <label style={{ fontSize:'12px' }}>USSD/PAYATTUSSD NETWORK</label>
                                </div>
                                <div className="col-4 text-start" style={{ fontSize:'15px', marginTop:'1px' }}>
                                    <b>&#8358;1,460</b>
                                </div>

                                <div className="col-8 text-end">
                                    <label style={{ fontSize:'13px' }}>Interest Paid 01-01-2024</label>
                                </div>
                                <div className="col-4 text-start" style={{ fontSize:'15px', marginTop:'2px' }}>
                                    <b>&#8358;25.32</b>
                                </div>

                                <div className="col-8 text-end">
                                    <label style={{ fontSize:'13px' }}>WHT on Interest 01-01-2024</label>
                                </div>
                                <div className="col-4 text-start" style={{ fontSize:'15px', marginTop:'2px' }}>
                                    <b>&#8358;2.53</b>
                                </div>
                            </div>
                            
                        </div>
                    </>
                }
                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {(steps === 1)
                        ?
                        "Proceed"
                        :
                        steps === 2 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 2) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}