import React, { useEffect, useState } from "react";


export default function ContactUs(props){
    const { closeHandler, titles, setIsCheckBVN, isCheckBVN } = props;

    return (
        <div className="mainDiv">
            
            <div className="container contactForm">
                <p className="mb-3 mt-1" style={{ color:'#222', fontWeight:'600', lineHeight:'21px', fontSize:'16px' }}>{titles}</p>

                <div className="row mb-1">
                    <div className="col-4">Hotline</div>
                    <div className="col-8"><a href="tel:+23480123456789">080123456789</a></div>
                </div>
                <div className="row mb-1">
                    <div className="col-4">WhatsApp</div>
                    <div className="col-8"><a target="_blank" href="https://api.whatsapp.com/send?phone=080987654321">080987654321</a></div>
                </div>
                <div className="row mb-1">
                    <div className="col-4">Email</div>
                    <div className="col-8"><a href="mailto:text@ubasmartsell.com">text@ubasmartsell.com</a></div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">FAQs</div>
                    <div className="col-8"><a target="_blank" href="https://www.ubagroup.com/about-uba/help/faq/">UBA Group FAQs</a></div>
                </div>
            </div>

            <hr />
            
            <div className="all_btns mt-10 mb-2 text-center">
                <span className="closeBtn" onClick={() => setIsCheckBVN(!isCheckBVN)}>{'<'} Back</span>
                
                <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
            </div>
        </div>
    );
}