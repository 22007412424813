import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LeftNav from "./LeftNav";

export default function MainScreen(){

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
          e.preventDefault();
          setDeferredPrompt(e);
        };
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        // return () => {
        //   window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        // };
    }, []);
    

    const handleInstallApp = async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          const { outcome } = await deferredPrompt.userChoice;
          if (outcome === 'accepted') {
            setDeferredPrompt(null);
          }
          console.log(outcome);
        }
    }




    return (
        <div className="container p-xs-0">
            <div className="row p-xs-0 align-items-center">

                <LeftNav />

                <div className="col-lg-6 col-md-12">
                    <div className="mockup_phone1 mockup_nomoney">
                        <div className="mockup_phone">
                            <div className="onboarding-section section-b-space_ pt-0" id="onboardingBody">
                                <div className="swiper onboarding onboarding2">
                                    
                                    <div className="p-4 mb--50">
                                        <img className="img-logo" src="../assets/images/uba-logo.png" alt="UBA Logo" />
                                    </div>
                
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="onboarding-wrapper">
                                                <div className="onboarding-wrapper">
                                                    <div className="container-fluid">
                                                        <div className="profile-image">
                                                            <img className="img-fluid img-fluid2" src="../assets/images/bills.png" alt="1" />
                                                        </div>
                                                    </div>
                
                                                    <div className="poster-section">
                                                        <div className="custom-container p-xs-0">
                                                            <div className="p-3 mt--10">
                                                                <img className="img-fluid img-fluid1 poster-img ml--20" src="../assets/images/ussd_girl2.png" alt="girl" />
                                                            </div>
                
                                                            <div className="poster-content pb-80">
                                                                <h2>Smart SELL</h2>
                                                                <img className="img-fluid line" src="../assets/images/line-design.svg" alt="border-design" />
                                                                    <h5 style={{ fontWeight:'600', lineHeight:'20px' }}>USSD just got easier with a better functional graphical user interface!</h5>

                                                                    <Link className="onboarding-next next-btn3" to="/dashboard/">
                                                                        <img className="img-fluid arrow" src="../assets/images/arrow.svg" alt="arrow" />
                                                                    </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div className="offcanvas offcanvas-bottom addtohome-popup theme-offcanvas" tabIndex="-1" id="offcanvas">
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                <div className="offcanvas-body small">
                                    <div className="app-info">
                                        {/* <img src="../assets/images/mini-logo.png" className="img-fluid" alt="" /> */}
                                        <img src="../assets/images/uba_small_logo.png" className="img-fluid img-fluid4" alt="" />
                                        <div className="content">
                                            <h3>UBA Smart Sell</h3>
                                            <a href="#">www.smartsell-app.com</a>
                                        </div>
                                    </div>
                                    <a href="#!" className="mt--30 btn theme-btn install-app btn-inline home-screen-btn m-0" id="installApp" onClick={handleInstallApp}>Add to Home
                                        Screen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}