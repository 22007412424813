import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";


export default function LifeStyle(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone, cusDetails, isSuccess1 } = props;

    const [togglePassword, setTogglePassword] = useState(false);
    const [amount1, setAmount1] = useState(0);


    const lifestyleData = [
        { id: 1, name: "Event Ticketing" },
        { id: 2, name: "Airline Ticketing" },
        { id: 3, name: "Konga Payment" },
        { id: 4, name: "LCC" },
        { id: 5, name: "Bet9ja" },
        { id: 6, name: "BetKing" },
    ]
    const [datas, setDatas] = useState(lifestyleData);

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }


    const handleFormActn = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }
        console.log(customData);

        setValue("pin", "");

        if(codes === "lifestyle"){
            if(steps === 1){
                setSteps(2);

            }else if(steps === 2){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(3);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }

            }else if(steps === 3){
                setSteps(4);

            }else if(steps === 4){
                setSteps(5);

            }else if(steps === 5){
                if(inputDatas.payment_opt === "||llc_payment"){
                    setSteps(6);
                }
                if(inputDatas.payment_opt === "||llc_balance"){
                    setSteps(1);
                }
            }else if(steps === 6){
                setSteps(7);

            }else if(steps === 7){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleAmountChange = (event) => {
        setAmount1(Number(event.target.value));
    };



    return (
        <form className="mt--5" onSubmit={handleSubmit(handleFormActn)}>
            
            <div className="mainDiv">
                {
                    (steps === 1) &&
                    <div className="text-center pt-10 pb-0">
                        <h4 style={{ fontWeight:'700', fontSize:'16px' }} className="mb-15">
                            Lifestyle
                        </h4>

                        <select
                            {...register("ticket_type", { 
                                required: "Please ticket type"
                            })}
                        >
                            <option value="">-Select One-</option>
                            {datas.length !==0 && datas.map((data1, index) => {
                                return (
                                    <option key={index} value={`${data1.id}||${data1.name}`}>
                                        {data1.name}
                                    </option>
                                )
                            })}
                        </select>
                        {errors.ticket_type && (<div className="errors">{errors.ticket_type?.message}</div>)}
                    </div>
                }

                {
                    steps === 2 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 3 &&
                    <UserAccounts register={register} errors={errors} />
                }

                {
                    steps === 4 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>Please Select One</p>

                        <div className="row radios mt-20 mb--10">
                            <div className="col-12 text-left">
                                <input id="llc_balance" {...register("payment_opt")} type="radio" value="||llc_balance" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'llc_balance', setValue)}  />
                                <label htmlFor="llc_balance">Check LLC Balance</label>
                            </div>
                            
                            <div className="col-12 text-left mt--20">
                                <input id="llc_payment" {...register("payment_opt")} type="radio" value="||llc_payment" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'llc_payment', setValue)}  />
                                <label htmlFor="llc_payment">LLC Payment</label>
                            </div>
                        </div>
                    </>
                }

                {
                    (steps === 5 && inputDatas.payment_opt === "||llc_balance") &&
                    <>
                        <p className="mb-3 mt-2" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>LLC Balance</p>

                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                <p className="mb-1" style={{ fontSize:'16px' }}>Balance is <b>&#8358;1,460</b></p>
                                <p style={{ fontSize:'14px', color:'#096191' }}>Last Transaction: 13-05-2024 @ 12:34pm</p>
                            </div>
                        </div>
                    </>
                }

                {
                    (steps === 5 && inputDatas.payment_opt === "||llc_payment") &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>Please enter a valid ETag code<br />(in format xxxx-xx)</p>

                        <input type="number" className="not_amount"  
                        placeholder="Enter ETag code"
                        autoComplete="off"
                            {...register("etag_code", { 
                                required: "Please enter ETag code (xxxx-xx)",
                                pattern: {
                                    value: /^\d{6}$/,
                                    message: "ETag code must be at least 6 digits"
                                }
                            })}
                        />
                        {errors.etag_code && <label className="errors">{errors.etag_code?.message}</label>}


                        <input type="number" className="focusInput" 
                        placeholder={`Enter Amount`}
                        autoComplete="off"
                            {...register("amount", { 
                                required: `Please enter amount`,
                                min: {
                                    value: 50,
                                    message: "Amount should start from N50"
                                },
                            })}
                            onChange={handleAmountChange}

                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                        <div className="ml-5 mt--5 mb-15 text-center" style={{ fontSize:'12px', lineHeight:'19px', color:'#069' }}>Amount greater than <b>N20,000</b> will require secure Pass.</div>


                        {
                            amount1 >= 20000 && (
                                <>
                                    <input type="password" className="focusInput mt--10" 
                                    placeholder={`Enter Secure Pass`}
                                    autoComplete="off"
                                        {...register("secure_pass", { 
                                            required: `Please enter secure pass`,
                                        })}
                                    />
                                    {errors.secure_pass && <label className="errors">{errors.secure_pass?.message}</label>}
                                </>
                            )
                        }
                    </>
                }

                {
                    (steps === 6 && inputDatas.payment_opt === "||llc_payment") &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>Your request is been processed. Do you wish to save this beneficiary?</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }

                {
                    (steps === 7 && inputDatas.payment_opt === "||llc_payment") &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>You have successfully added <b>Chinny Anthony</b> to your beneficiary list.<br />Thank you for using UBA Smart Sell.</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }
                

                <div className="all_btns mt-10 text-center">
                    {
                        // (steps === 5 && inputDatas.payment_opt === "||llc_payment") && (
                            <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                                {steps === 3
                                ?
                                "Confirm"
                                :
                                steps === 6
                                ?
                                "Add To Beneficiary List"
                                :
                                (steps === 5 && inputDatas.payment_opt === "||llc_balance")
                                ?
                                "Go To Main Menu"
                                :
                                steps === 7 ? "Go To Main Menu" : "Next"
                                }
                            </button>
                        // )
                    }
                    
                    {
                        (steps > 1 && steps < 6) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}