import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";


export default function ClickCredit(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, inputDatas, setInputDatas } = props;

    const [togglePassword, setTogglePassword] = useState(false);

    const months = Array.from({ length: 12 }, (_, index) => index + 1);

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }


    const handleForms = async (data) => {
        
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));

        const lesser_amt = data.amount_lesser;
        // const amount = data.amount;

        if(lesser_amt !== ""){
            data.amount = lesser_amt;
        }
        
        const customData = {
            phone: cusPhone ? cusPhone : storedDetlsString.phone,
            amount: data.amount,
            duration: data.duration
        }
        setInputDatas(customData);

        // console.log(customData);
        // console.log(codes);
        // return;

        if(codes === "click_credit"){
            
            if(steps === 1){
                setSteps(2);
                
            }else if(steps === 2){

                // console.log(3);
                setSteps(3);

            }
            else if(steps === 3){
                // console.log(4);
                // setSteps(4);
                
            }else if(steps === 4){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(handleForms)}>
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-2" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <div className="text-center mb-2">
                            <h4 style={{ fontSize:'17px', lineHeight:'30px' }}>
                                You are eligible for
                                <p style={{ marginTop:'-4px' }}><b style={{ fontSize:'27px', color:'#d93737' }}>&#8358;500,000</b></p>
                                <p style={{ fontSize:'15px', color:'#d93737', marginTop:'-5px' }}>Monthly Interest: &#8358;25,000</p>
                            </h4>
                        </div>

                        <input type="hidden" defaultValue={`500000`}
                            {...register("amount", { 
                                required: "Please enter amount",
                            })}
                        />


                        <hr className="mt-5" />
                        <div className="text-center mt--10 mb-2">
                            <b>OR</b>
                        </div>
                        
                        <input type="number" className="" placeholder="Enter Lesser Amount"  autoComplete="off"
                            {...register("amount_lesser", { 
                                min: {
                                    value: 10,
                                    message: "Amount should start from N5,000"
                                },
                                max: {
                                    value: 500000,
                                    message: "Amount should stop at N500,000"
                                },
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                    </>
                }

                {
                    steps === 2 &&
                    <>
                        <p className="mt-2 mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>Select Duration</p>
                        
                        <select className=""
                            {...register("duration", { 
                                required: "Please select a duration"
                            })}
                            // onChange={handlePackageChange}
                        >
                            <option value="">-Select Duration-</option>
                            {months.map(month => (
                                <option key={month} value={month}>{month} {month > 1 ? "Months" : "Month"}</option>
                            ))}
                        </select>
                        {errors.duration && <label className="errors">{errors.duration?.message}</label>}
                    </>
                }

                {
                    steps === 3 &&
                    <>
                        <div className="enterPin">
                            <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>

                            <p className="mb-4 mt-2" style={{ fontSize: '17px', fontWeight: 500 }}>Service coming soon</p>

                            {/* <div className="row c_details mb-20">
                                <div className="col-4 text-end mb-1">
                                    <div><b>Reference:</b></div>
                                </div>
                                <div className="col-8 mb-1">
                                    <div style={{ textTransform:'capitalize' }}>#{inputDatas.refs1}</div>
                                </div>
                                
                                <div className="col-4 text-end mb-1">
                                    <div><b>Category:</b></div>
                                </div>
                                <div className="col-8 mb-1">
                                    <div>{(inputDatas.biller).toUpperCase()}</div>
                                </div>

                                <div className="col-4 text-end mb-1">
                                    <div><b>Package:</b></div>
                                </div>
                                <div className="col-8 mb-1">
                                    <div>{inputDatas.package}</div>
                                </div>

                                <div className="col-4 text-end mb-1">
                                    <div><b>Meter No.:</b></div>
                                </div>
                                <div className="col-8 mb-1">
                                    <div>{inputDatas.meter_number}</div>
                                </div>

                                <div className="col-4 text-end mb-1">
                                    <div><b>Amount:</b></div>
                                </div>
                                <div className="col-8 mb-1">
                                    <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                                </div>
                            </div> */}
                        </div>
                    </>
                }

                {
                    steps === 333 &&
                    <>
                        <div className="text-center pt-5">
                            {/* <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p> */}

                            <p className="mb-10" style={{ fontWeight:'600', fontSize:'14px', lineHeight:'20px' }}>Enter the OTP code below from your mobile phone.</p>

                            <input type="password" className="passwordInput focusInput" placeholder="Enter OTP"  autoComplete="off"
                                {...register("code", { 
                                    required: "Please enter your OTP",
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: "OTP must be 6 digits"
                                    }
                                })}
                            />
                            {errors.pin && <label className="errors">{errors.pin?.message}</label>}
                        </div>
                    </>
                }

                {
                    steps === 4 &&
                    <div className="successDiv text-center pt-10 mb-20" style_="display: none;">
                        <p className="mb-3" style={{ lineHeight:'18px', fontWeight:'600', fontSize:'15px' }}>Your Transaction PIN has been updated successfully!</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }
                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps === 2
                        ?
                        "Proceed"
                        :
                        steps === 3
                        ?
                        "Submit"
                        :
                        steps === 4 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 4) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}