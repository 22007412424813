import React, { useEffect, useState } from "react";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import ContactUs from "./ContactUs";
import UserAccounts from "./UserAccounts";


export default function Enquiry(props){
    const { closeHandler, titles, handleCheckboxClick, isLoading, setShowErrMessages, register, errors, handleSubmit, codes, isSubmitting, isValid, setValue, cusDetails, setInputDatas, openDivHandler } = props;

    const [innerSteps, setInnerSteps] = useState(1);
    const [isCheckBVN, setIsCheckBVN] = useState(true);

    const goBack = (innerSteps) => {
        const backSteps = innerSteps - 1;
        if(backSteps === 2){
            setInnerSteps(innerSteps - 2);
            return;
        }
        setInnerSteps(backSteps);
    }

    const handleForms = (data) => {
        setInputDatas(data);

        if(codes === "enquiries"){
            if(innerSteps === 1){
                setInnerSteps(2);
                setValue("pin", "");

            }else if(innerSteps === 2){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setInnerSteps(3);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }

            }else if(innerSteps === 3){
                setInnerSteps(4);

            }else if(innerSteps === 4){
                setInnerSteps(5);

            }else if(innerSteps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setInnerSteps(1);
                }, 300);
            }
        }
    }


    return (
        <div>
            {
                isCheckBVN && 
                <form className="mt--5" onSubmit={handleSubmit(handleForms)}>
                    <div className="mainDiv">
                        <div className="all_btns text-center">
                            {
                                innerSteps === 1 && (
                                    <>
                                        <p className="mb-4" style={{ color:'#222', fontWeight:'600', lineHeight:'22px', fontSize:'16px' }}>{titles}</p>
                            
                                        <button className="btn btn-primary mb-10" type="submit">Check BVN</button>
                                        <button className="btn btn-primary" type="button" onClick={() => setIsCheckBVN(!isCheckBVN)}>Our Support</button>
                                    </>
                                )
                            }

                            {
                                innerSteps === 2 &&
                                <EnterPin register={register} errors={errors} />
                            }

                            {
                                innerSteps === 3 &&
                                <>
                                    <p className="mb-4 mt-2" style={{ color:'#222', fontWeight:'600', lineHeight:'22px', fontSize:'17px' }}>Transaction fee of N20 applies. Do you wish to continue?</p>
                                </>
                            }

                            {
                                innerSteps === 4 &&
                                <UserAccounts register={register} errors={errors} />
                            }

                            {
                                innerSteps === 5 &&
                                <>
                                    <div className="row mt-3 mb-3">
                                        <div className="col-12 text-center">
                                            <p className="mb-0" style={{ fontSize:'18px' }}><b>Your BVN:</b> <b style={{ letterSpacing:'0.7px' }}>22282145778</b></p>

                                            <p className="mb-1" style={{ fontSize:'13px', color:'red', opacity:'0.7' }}>Always keep this safe with you</p>
                                        </div>
                                    </div>
                                </>
                            }
                            
                            <div className="all_btns mt-2 text-center">
                                {
                                    innerSteps >= 2 &&
                                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                                        
                                        {innerSteps === 2
                                        ?
                                        "Yes, Continue"
                                        :
                                        innerSteps === 5 ? "Go To Main Menu" : "Yes, Continue"
                                        }
                                    </button>
                                }

                                {
                                    (innerSteps > 1 && innerSteps < 5) && <span className="closeBtn" onClick={() => goBack(innerSteps)}>{'<'} Back</span>
                                }
                                <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                            </div>
                        </div>
                    </div>
                </form>
            }

            {
                !isCheckBVN && <ContactUs setIsCheckBVN={setIsCheckBVN} isCheckBVN={isCheckBVN} closeHandler={closeHandler} titles="Any challenge, enquiry or suggestion, we are always here to assist" />
            }
        </div>
    );
}