import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";


export default function GenerateOTP(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone, cusDetails, isSuccess1 } = props;

    const [togglePassword, setTogglePassword] = useState(false);
    // const [ntwkChange, setNtwkChange] = useState('Mtn');

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    const [randOTP, setRandOTP] = useState('');

    const generateRandomDigits = () => {
        const randomDigits = Math.floor(1000 + Math.random() * 9000).toString();
        return randomDigits;
    };


    const handleFormActn = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }

        // console.log(customData);

        if(data.atti_type === "||million"){
            setShowErrMessages("error||" + "This feature is unavailable at the moment!");
            setTimeout(() => {
                setShowErrMessages(false);
            }, 3000);
            return;
        }
        setValue("pin", "");

        // return;
        if(codes === "generate_otp"){
            if(steps === 1){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(2);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 2){
                setSteps(3);
                setRandOTP(generateRandomDigits());
  
            }else if(steps === 3){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleInputChange = (e) => {
        const values = e.target.value;
        // setNtwkChange(values);
        // setGetNetworks(values);

        // if(values !== ""){
        //     setNtwkLoader(true);
        //     getDataBundle(values);
        // }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(handleFormActn)}>
            
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    (steps === 2) &&
                    <>
                        <div className="text-center pt-10 pb-10">
                            <h4 style={{ fontWeight:'700', fontSize:'16px' }}>This transaction will attract a fee of <b>N5</b></h4>
                            <p className="mt-10" style={{ lineHeight:'18px' }}>Do you wish to proceed?</p>
                        </div>
                    </>
                }

                {
                    steps === 3 &&
                    <>
                        <div className="row mt-3 mb-3">
                            <div className="col-12 text-center">
                                <p className="mb-1" style={{ fontSize:'18px' }}><b>Your OTP:</b> <b style={{ letterSpacing:'0.7px' }}>{randOTP}</b></p>

                                <p className="mb-1" style={{ fontSize:'13px', color:'red', opacity:'0.7' }}>Always keep this safe with you</p>
                            </div>
                        </div>
                    </>
                }

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {(steps === 2)
                        ?
                        "Proceed"
                        :
                        steps === 3 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 3) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}