import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function EnterPin(props){
    const { register, errors } = props;

    return (
        <div className="enterPin mb--5">
            <p className="mb-0" style={{ fontSize: '22px', fontWeight: 600 }}>Enter PIN</p>
            <p className="mb-3">Enter your transaction pin to continue</p>

            <input type="password" className="passwordInput focusInput" placeholder="Enter your PIN"
                {...register("pin", { 
                    required: "Please enter your PIN",
                    pattern: {
                        value: /^\d{4}$/,
                        message: "Pin must be 4 digits"
                    }
                })}
            />
            {errors.pin && <label className="errors">{errors.pin?.message}</label>}
        </div>
    );
}