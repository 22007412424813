import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";

export default function Transfers(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, openDivHandler, bankName, isSuccess1, setIsSuccess1, showMessages, setShowMessages, setValue, acctNo, setInputDatas } = props;

    useEffect(() => {
        setValue('acct_number', acctNo);
    }, []);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setIsSuccess1(true);
            setShowMessages(true);
        } else {
            setIsSuccess1(false);
        }
    }

    const goBack = (steps) => {
        const backSteps = steps - 1;

        if(backSteps === 4){
            setSteps(steps - 3);
            return;
        }
        setSteps(backSteps);
    }


    const [filteredDatas, setFilteredDatas] = useState(listOfBanks);

    const handleSearchChange = (event) => {
            const inputValue = event.target.value.toLowerCase();
            const filtered = listOfBanks.filter((data1) =>
            data1.name.toLowerCase().includes(inputValue)
        );
        setFilteredDatas(filtered);
    }


    const handleTransfer = async (data) => {
        // console.log(data);
        setInputDatas(data);

        // console.log(codes);

        if(codes === "transfer_uba"){
            if(steps === 1){
                setSteps(2);
                // console.log(2);
            }else if(steps === 2){
                setSteps(3);
                // console.log(3);
                
            }else if(steps === 3){
                setSteps(4);
                // console.log(4444);
                setTimeout(() => {
                    setValue('pin', '');
                }, 30);

            }else if(steps === 4){
                setSteps(5);
                // console.log(5);

            }else if(steps === 5){
                setSteps(6);
                // console.log(6);

            }else if(steps === 6){
                setSteps(7);
                // console.log(7);

            }else if(steps === 7){
                // console.log(8);
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                    // console.log(1);
                }, 300);
            }
        }
    }



    return (
        <form className="mt--5" onSubmit={handleSubmit(handleTransfer)}>
            <div className="mainDiv pt-5">
                <input type="hidden" autoComplete="off" value={refs1}
                    {...register("refs1")}
                />


                {
                    steps === 1 &&
                    <div>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <div className="radios mt--5">
                            <select style={{ opacity: isLoading ? '1' : 1 }}
                                {...register("selfOthers", { 
                                    required: "Please select transfer type"
                                })}
                                // onChange={handleNtwkChange}
                                onChange={(e) => handleCheckboxClick(e, '', setValue)}
                            >
                                <option value="Self" selected>Transfer to UBA (Self)</option>
                                <option value="Others">Transfer to Other Banks</option>
                                <option value="Microfinance">Transfer to Microfinance & Others</option>
                                <option value="Microfinance">Transfer to UBA Prepaid</option>
                            </select>
                        </div>


                        <input type="number" className="focusInput" placeholder="Type amount to transfer"  autoComplete="off"
                            {...register("amount", { 
                                required: "Please enter your amount",
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}


                        <input type="number" className="not_amount" placeholder="Enter beneficiary account number"  autoComplete="off"
                            {...register("acct_number", { 
                                required: "Please enter beneficiary account number",
                                pattern: {
                                    value: /^\d{10}$/,
                                    message: "Account number must be at least 10 digits"
                                }
                            })}
                            onChange={handleInputChange}
                        />

                        {
                            showMessages && (
                                <div>
                                    {isSuccess1 ? (
                                        <div className="errors errors1" style={{ color: 'green' }}>Chinny E. Anthony</div>
                                    ) : (
                                        <div className="errors errors1" style={{ color: 'green' }}>{bankName}</div>
                                    )}
                                    
                                    {!isSuccess1 && errors.acct_number && (
                                        <label className="errors">{errors.acct_number?.message}</label>
                                    )}
                                </div>
                            )
                        }

                        <div className="saved_list" onClick={() => openDivHandler('open_beneficiary', '')}>View Saved Beneficiaries</div>
                    </div>
                }
                {
                    steps === 2 &&
                    <div>
                        <p className="mb-2" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'16px' }}>Select Receipient Bank Below</p>
                        <div className="row radios1 mt--5 mb-20">

                            <div className="col-12">
                                <input type="text" className="not_amount focusInput pt-15 pb-15" placeholder="Search bank"  autoComplete="off"
                                    style={{ fontSize:'16px', textTransform:'capitalize' }}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <div className="col-12 inner_div_scroll pb-20">

                                {errors.banks && <label className="errors pt-1">{errors.banks?.message}</label>}
                                
                                {filteredDatas.map(bankList => (
                                    <div key={bankList.id} className="row mb-5">
                                        <div className="col-2 pr-0">
                                            <input 
                                            style={{ position: 'relative', zIndex:9, top:'5px' }} type="radio" value={`${bankList.id}||${bankList.name}`} id={bankList.id} name="banks" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'self', setValue)}

                                            {...register("banks", { 
                                                required: "Please select your bank",
                                            })}

                                            /> 
                                        </div>
                                        <div className="col-10 pl-0">
                                            <label htmlFor={bankList.id} className="bank_name">{bankList.name}</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 3 &&
                    <UserAccounts register={register} errors={errors} />
                }
                
                {
                    steps === 4 &&
                    <div className="enterPin mb-20">
                        <p className="mb-0 mt--5" style={{ fontSize: '20px', fontWeight: 600 }}>Confirm Your Details</p>
                        <p>You are about to make this transaction. Kindly confirm these details below and continue.</p>

                        <div className="row c_details mt-10">
                            <div className="col-5 text-end">
                                <div><b>Source:</b></div>
                            </div>
                            <div className="col-7 mb-1">
                                <div>{inputDatas.account_type ? inputDatas.account_type.split('||')[0] : null}</div>
                                <div style={{ fontSize:'13px', marginTop:'-2px', color:'#065884' }}>{inputDatas.account_type ? inputDatas.account_type.split('||')[1] : null}</div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Account Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>Chinny Anthony</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Account:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>{inputDatas.acct_number}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Bank Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600', lineHeight:'18px', fontSize:'13px', position:'relative', top:'3px' }}><b>{inputDatas.banks.split('||')[1]}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 5 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 6 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>You have successfully transfered <b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b> to <b>Chinny Anthony</b>. Do you want to add Chinny to your beneficiary list?</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }

                {
                    steps === 7 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>You have successfully added <b>Chinny Anthony</b> to your beneficiary list.<br />Thank you for using UBA Smart Sell.</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={(isSubmitting || !isValid) && !isSuccess1} style={{ opacity: ((isSubmitting || !isValid ) && !isSuccess1) ? '0.5' : 1 }}>
                        {steps === 3
                        ?
                        "Confirm"
                        :
                        steps === 6
                        ?
                        "Add To Beneficiary List"
                        :
                        steps === 7 ? "Go To Main Menu" : "Next"
                        }
                    </button>
                </div>

                <div className="mt--5 text-center">
                    {
                        (steps > 1 && steps < 6) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}