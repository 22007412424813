import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";

export default function AccountBal(props){
    const { closeHandler, titles, handleCheckboxClick, steps, setSteps, setGetNetworks, cusDetails, setValue, handleSubmit, isSubmitting, isValid, errors, codes, register, setShowErrMessages } = props;
    const [toggleNtwk, setToggleNtwk] = useState(false);


    const goBack = (steps) => {
        const backSteps = steps - 1;
        setSteps(backSteps);
    }

    const checkAcctBalance = (data) => {
        // console.log(data);
        if(codes === "acct_balance"){
            if(steps === 1){
                setSteps(2);
                // console.log(2);

            }else if(steps === 2){

                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(3);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 4){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(checkAcctBalance)}>
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-4 mt-2" style={{ color:'#222', fontWeight:'600', lineHeight:'22px', fontSize:'17px' }}>Transaction fee of N10 applies. Do you wish to continue?</p>
                    </>
                }

                {
                    steps === 2 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 3 &&
                    <>
                        <p className="mb-3 mt-2" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'20px' }}>{titles}</p>

                        <div className="row mb-3">
                            <div className="col-12 text-center">
                                <p className="mb-1" style={{ fontSize:'16px' }}>Main Balance is <b>&#8358;1,460</b></p>
                                <p style={{ fontSize:'14px', color:'#096191' }}>Last Transaction: 13-05-2024 @ 12:34pm</p>
                            </div>
                        </div>
                    </>
                }

                <div className="all_btns mt-10 text-center">

                    {
                        (steps === 1 || steps === 2) &&
                        <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                            Yes, Continue
                        </button>
                    }

                    {
                        (steps >= 1 && steps < 2) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>

                {/* <div className="all_btns mt-10 text-center">
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div> */}
            </div>
        </form>
    );
}