import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";


export default function PayAttitude(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone, cusDetails, isSuccess1 } = props;

    const [togglePassword, setTogglePassword] = useState(false);
    // const [ntwkChange, setNtwkChange] = useState('Mtn');

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    // console.log(inputDatas);


    const handleFormActn = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }

        // console.log(customData);

        if(data.atti_type === "||million"){
            setShowErrMessages("error||" + "This feature is unavailable at the moment!");
            setTimeout(() => {
                setShowErrMessages(false);
            }, 3000);
            return;
        }

        // return;
        if(codes === "pay_attitude"){
            if(steps === 1){
                setSteps(2);
                // console.log(2);
                setValue("pin", "");
            }else if(steps === 2){
                setSteps(3);
                // console.log(3);
                setValue("pin", "");

            }else if(steps === 3){
                setSteps(4);
                setValue("pin", "");

            }else if(steps === 4){
                setSteps(5);
                setValue("pin", "");

            }else if(steps === 5){
                setSteps(6);
                setValue("pin", "");

            }else if(steps === 6){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(7);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 7){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleInputChange = (e) => {
        const values = e.target.value;
        // setNtwkChange(values);
        // setGetNetworks(values);

        // if(values !== ""){
        //     setNtwkLoader(true);
        //     getDataBundle(values);
        // }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(handleFormActn)}>
            
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>PlayAttitude</p>

                        <div className="row radios mt-20 mb--10">
                            <div className="col-12 text-left">
                                <input id="receive_money" {...register("atti_type")} type="radio" value="||receive_money" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'receive_money', setValue)}  />
                                <label htmlFor="receive_money">Receive Money</label>
                            </div>
                            
                            <div className="col-12 text-left mt--20">
                                <input id="send_money" {...register("atti_type")} type="radio" value="||send_money" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'send_money', setValue)}  />
                                <label htmlFor="send_money">Send Money To Phone No</label>
                            </div>

                            <div className="col-12 text-left mt--20">
                                <input id="million" {...register("atti_type")} type="radio" value="||million" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'million', setValue)}  />
                                <label htmlFor="million" style={{ fontSize:'14px' }}>Play Who Wants To Be A Millionaire</label>
                            </div>
                        </div>
                    </>
                }

                {
                    (steps === 2 && inputDatas.atti_type !== "||million") &&
                    <UserAccounts register={register} errors={errors} />
                }

                {
                    (steps === 2 && inputDatas.atti_type === "||million") &&
                    <>
                    <p className="mb-3 mt-1" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>PlayAttitude</p>

                    <div className="row">
                        <div className="col-12">
                            <select
                                {...register("games", { 
                                    required: "Select a game"
                                })}
                                onChange={handleInputChange}
                            >
                                <option value="" selected>-Select A Game-</option>
                                <option value="Studio Play">Studio Play</option>
                                <option value="Weekly Play">Weekly Play</option>
                                <option value="Sponsor's Play">Sponsor's Play</option>
                            </select>
                        </div>
                    </div>
                    {errors.games && <label className="errors">{errors.games?.message}</label>}

                    <div className="row">
                        <div className="col-12">
                            <select
                                {...register("answers", { 
                                    required: "Select answer"
                                })}
                                onChange={handleInputChange}
                            >
                                <option value="" selected>-Select Answer-</option>
                                <option value="Lagos">Lagos</option>
                                <option value="Oyo">Oyo</option>
                                <option value="Delta">Delta</option>
                                <option value="Abuja">Abuja</option>
                                <option value="Kano">Kano</option>
                            </select>
                        </div>
                    </div>
                    {errors.answers && <label className="errors">{errors.answers?.message}</label>}
                    </>
                }

                {
                    (steps === 3 && inputDatas.atti_type !== "||million") &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <input type="number" className="not_amount"  
                        placeholder="Enter Recipient Number"
                        // placeholder={`Enter ${inputDatas.atti_type === "||send_money" ? "Recipient Number" : "Account Number"}`}
                        autoComplete="off"
                            {...register("reci_num", { 
                                required: "Please enter recipient number",
                                // required: `Please enter ${inputDatas.atti_type === "||prepaid_card" ? "physical card" : "account number"}`,
                                pattern: {
                                    value: /^\d{11}$/,
                                    message: "Recipient number must be at least 11 digits"
                                    // message: `${inputDatas.atti_type === "||prepaid_card" ? "Physical card" : "Account number"}  must be at least 10 digits`,
                                }
                            })}
                        />
                        {errors.reci_num && <label className="errors">{errors.reci_num?.message}</label>}


                        <input type="number" className="focusInput" 
                        // placeholder="Enter amount to request"
                        placeholder={`Enter Amount to ${inputDatas.atti_type === "||send_money" ? "Send" : "Request"}`}
                        autoComplete="off"
                            {...register("amount", { 
                                // required: "Enter amount to request",
                                required: `Please enter amount to ${inputDatas.atti_type === "||send_money" ? "send" : "request"}`,
                                min: {
                                    value: 100,
                                    message: "Amount should start from N100"
                                },
                                max: {
                                    value: 20000,
                                    message: "Amount should stop at N20,000"
                                },
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                        
                    </>
                }

                {
                    (steps === 4 && inputDatas.atti_type !== "||million") &&
                    <>
                        <div className="text-center pt-10 pb-10">
                            <h4 style={{ fontWeight:'700', fontSize:'16px' }}>This transaction will attract a service charge of <b>N10.75</b></h4>
                            <p className="mt-10" style={{ lineHeight:'18px' }}>Do you wish to proceed?</p>
                        </div>
                    </>
                }

                {
                    steps === 5 &&
                    <div className="enterPin mb-20">
                        <p className="mb-0 mt--5" style={{ fontSize: '20px', fontWeight: 600 }}>Confirm Your Details</p>
                        <p>You are about to make this transaction. Kindly confirm these details below and continue.</p>

                        <div className="row c_details mt-10">
                            
                            <div className="col-5 text-end">
                                <div><b>Type:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600', textTransform:'capitalize' }}><b>{inputDatas.atti_type.split('||')[1].replace(/_/g, ' ')}</b></div>
                            </div>
                            <div className="col-5 text-end mb-1 mt-1">
                                <div><b>{inputDatas.atti_type === "||receive_money" ? "Destination" : "Acct. Source"}:</b></div>
                            </div>
                            <div className="col-7 mb-1 mt-1">
                                <div>{inputDatas.account_type ? inputDatas.account_type.split('||')[0] : null}</div>
                                <div style={{ fontSize:'13px', marginTop:'-2px', color:'#065884' }}>{inputDatas.account_type ? inputDatas.account_type.split('||')[1] : null}</div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Receipient:</b></div>
                            </div>
                            <div className="col-7 mb-1">
                                <div>{inputDatas.reci_num}</div>
                                <div style={{ fontSize:'13px', marginTop:'-4px', marginBottom:'5px', color:'#069' }}>(Chinny Anthony)</div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 6 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 7 &&
                    <div className="successDiv text-center pt-10 mb-20" style_="display: none;">
                        <p className="mb-3" style={{ lineHeight:'18px', fontWeight:'600', fontSize:'15px' }}>Your request was successful and peer has been notified! Thank you for using UBA Smart Sell</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }
                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {(steps === 3)
                        ?
                        "Proceed"
                        :
                        // steps === 4
                        // ?
                        // "Submit"
                        // :
                        steps === 7 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 7) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}