import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import UserAccounts from "./UserAccounts";

export default function CableTV(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, billTypes, setInputDatas, setValue, setShowErrMessages, cusDetails, titleName, setTitleName, reset } = props;

    const [isSuccess, setIsSuccess] = useState(false);
    const [isAmt, setIsAmt] = useState(0);
    const [steps1, setSteps1] = useState(1);


    const goBack = (steps) => {
        const backSteps = steps - 1;
        setSteps1(backSteps);
    }


    let packageName = "";
    let packageDuration = "";
    let amounts = "0.00";

    if (inputDatas && inputDatas.package) {
        const packageType = inputDatas.package;
        const packageArray = packageType.split("||");
        if (packageArray.length >= 2) {
            packageName = packageArray[0];
            // packageDuration = packageArray[1];
            amounts = packageArray[1];
        }
    }


    const handleForms = (data) => {
        // console.log(data);
        setInputDatas(data);
        
        if(steps1 === 1){
            setSteps1(2);
            // console.log(2);
        }else if(steps1 === 2){
            setSteps1(3);
            // console.log(3);
        }else if(steps1 === 3){
            setSteps1(4);
            // console.log(4);
        }else if(steps1 === 4){
            setSteps1(5);
            // console.log(5);
        }else if(steps1 === 5){
            setSteps1(6);
            // console.log(6);
        }else if(steps1 === 6){
            closeHandler(codes);
            setTimeout(() => {
                setSteps1(1);
            }, 300);
        }
    }

    const generateRandomAmount = () => {
        const minAmount = 10;
        const maxAmount = 500;
        const randomStep = Math.floor(Math.random() * (maxAmount - minAmount + 1)) + minAmount;
        return randomStep * 100;
    }

    const handlePackageChange = (e) => {
        const values = e.target.value;
        const amounts = values.split("||")[1];
        if(values !== ""){
            setIsAmt(amounts);
        }
    }

    const handleNtwkChange = (e) => {
        setValue("amount", '0');
        setValue("package", '');
        setIsAmt(0);
        // console.log('empty');
    }
    



    return (
        <form className="mt--5" onSubmit={handleSubmit(handleForms)}>
            <div className="mainDiv pt-5">

                {/* <p className="mb-3" style={{ color:'#222', fontWeight:700, lineHeight:'20px', fontSize:'17px' }}><b>{titleName}</b></p> */}

                <input type="hidden" autoComplete="off" value={refs1}
                    {...register("refs1")}
                />

                {
                    steps1 === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:700, lineHeight:'20px', fontSize:'17px' }}><b>{titleName}</b></p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <select className=""
                            {...register("biller", { 
                                required: "Please select a biller"
                            })}
                            onChange={handleNtwkChange}
                        >
                            <option value="">-Select Biller-</option>
                            <option value="dstv">DSTV</option>
                            <option value="gotv">GOTV</option>
                            <option value="startimes">STARTIMES</option>
                        </select>
                        {errors.biller && <label className="errors">{errors.biller?.message}</label>}


                        <select className=""
                            {...register("package", { 
                                required: "Please select a package"
                            })}
                            onChange={handlePackageChange}
                        >
                            <option value="">-Select Package-</option>
                            <option value="Compact Plus||2500">Compact Plus</option>
                            <option value="Compact and Asia||5000">Compact and Asia</option>
                            <option value="Confam||15000">Confam</option>
                            <option value="French Touch||9400">French Touch</option>
                        </select>
                        {errors.package && <label className="errors">{errors.package?.message}</label>}

                        <input type="hidden" autoComplete="off"
                            {...register("amount", { 
                                required: "Please choose amount",
                            })}
                            value={isAmt}
                        />

                        {
                            isAmt > 0 &&
                            <label type="number" className="formLabels">&#8358;{parseFloat(isAmt).toLocaleString()}</label>
                        }
                        
                    </>
                }

                {
                    steps1 === 2 &&
                    <div className="text-center">
                        <p className="mb-0 mt-5" style={{ fontSize: '15px', lineHeight:'22px', fontWeight: 600, fontSize:'16px' }}>
                            <b>Enter Your IUC Number</b>
                        </p>
                        <p className="mb-3" style={{ color:'#555' }}>You can find it at the back of your decoder</p>

                        <div className="row text-center">
                            <div className="col-12">
                                <input 
                                    className="mt-1 focusInput"
                                    style={{ position: 'relative', zIndex:9, background:'none', border:'1px solid #999', color:'#d93737' }} type="text" autoComplete="off"
                                    placeholder="_ _ _ _ _ _ _ _ _ _ _"
                                    {...register("iuc_number", { 
                                        required: "Please enter your IUC number",
                                        pattern: {
                                            value: /^\d{11}$/,
                                            message: "IUC must be 11 digits"
                                        }
                                    })}
                                /> 
                                {errors.iuc_number && <label className="errors">{errors.iuc_number?.message}</label>}
                            </div>
                        </div>
                    </div>
                }

                {
                    steps1 === 3 &&
                    <UserAccounts register={register} errors={errors} />
                }

                {
                    steps1 === 4 &&
                    <div className="enterPin">
                        <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>

                        <div className="row c_details mb-20">
                            <div className="col-4 text-end mb-1">
                                <div><b>Reference:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ textTransform:'capitalize' }}>#{inputDatas.refs1}</div>
                            </div>
                            
                            <div className="col-4 text-end mb-1">
                                <div><b>Account:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.account_type ? inputDatas.account_type.split('||')[0] : null}</div>
                                <div style={{ fontSize:'13px', marginTop:'-2px', color:'#065884' }}>{inputDatas.account_type ? inputDatas.account_type.split('||')[1] : null}</div>
                            </div>
                            
                            <div className="col-4 text-end mb-1">
                                <div><b>Category:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{(inputDatas.biller).toUpperCase()}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Package:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{packageName}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>IUC No.:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.iuc_number}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(amounts).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps1 === 5 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps1 === 6 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3">You have successfully subscribed your <b>{(inputDatas.biller).toUpperCase()}</b> with <b>&#8358;{parseFloat(amounts).toLocaleString()}</b>. Thank you for using UBA Smart Sell</p>
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps1 === 2
                        ?
                        "Next"
                        :
                        steps1 === 4
                        ?
                        "Proceed"
                        :
                        steps1 === 6 ? "Go To Main Menu" : "Next"
                        }
                    </button>
                </div>

                <div className="text-center">
                    {
                        (steps1 > 1 && steps1 < 6) && <span className="closeBtn" onClick={() => goBack(steps1)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}