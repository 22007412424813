import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import { useForm } from "react-hook-form";
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";

export default function CreatePhoneNumber(props){
    const { closeHandler, cmdNext, titles, isLoading, steps, setSteps, inputDatas, getNetworks, handleCheckboxClick, codes, setInputDatas, setShowErrMessages, setIsDisabledButton, setIsLoading, disableCreatePhone, setDisableCreatePhone, cusPhone, createPinRef, setCusDetails, setCusPhone } = props;

    const {register, handleSubmit, reset, getValues, setValue, formState: {errors, isValid, isSubmitting}} = useForm({mode: "onChange"});
    const [isPhone, setIsPhone] = useState(cusPhone);

    const [isLogin, setIsLogin] = useState(false);

    const goBack = (steps) => {
        const backSteps = steps - 1;
        setSteps(backSteps);
    }

    useEffect(() => {
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setIsPhone(storedDetlsString ? storedDetlsString.phone : null);
    }, []);


    const handleCreateNumber = async (data) => {
        setInputDatas(data);
        
        if(codes === "create_number"){
            if(steps === 1){
                // console.log(222);
                try {
                    const response = await fetch(API_ROUTES.CREATE_PHONE, {
                        method: "POST",
                        headers: { ...headers },
                        body: JSON.stringify(data),
                    });
                    const responseJSON = await response.json();
                    if(responseJSON.status.toString() === "200"){
                        setSteps(2);

                    }else if(responseJSON.status.toString() === "300"){
                        // setSteps(2);
                        if(responseJSON.data === 0){ // user did not create pin after successful register
                            if (createPinRef.current) {
                                createPinRef.current.click();
                            }
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }else{
                        if(responseJSON.status.toString() === "500"){
                            // setShowErrMessages(true);
                            setShowErrMessages("error||" + responseJSON.required_fields);
                            setTimeout(() => {
                                setShowErrMessages(false);
                            }, 3000);
                            return;
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }
                } catch (error) {
                    setShowErrMessages("error||" + error.message);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 2){
                try {
                    const response = await fetch(API_ROUTES.VERIFY_OTP, {
                        method: "POST",
                        headers: { ...headers },
                        body: JSON.stringify(data),
                    });
                    const responseJSON = await response.json();
                    if(responseJSON.status.toString() === "200"){
                        const customData = {
                            phone: data.phone
                        }
                        setIsPhone(data.phone);

                        localStorage.setItem('stored_details', JSON.stringify(customData));
                        setSteps(3);
                        setIsDisabledButton(false);
                        setIsLoading(false);
                        setDisableCreatePhone(true);
                        
                    }else{
                        if(responseJSON.status.toString() === "500"){
                            setShowErrMessages("error||" + responseJSON.required_fields);
                            setTimeout(() => {
                                setShowErrMessages(false);
                            }, 3000);
                            return;
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }
                } catch (error) {
                    // console.log(error);
                    setShowErrMessages("error||" + error.message);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }

            }else if(steps === 3){
                closeHandler(codes);
                // console.log(444);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleAuthLogin = async (data) => {
        setInputDatas(data);
        try {
            const response = await fetch(API_ROUTES.VERIFY_PIN, {
                method: "POST",
                headers: { ...headers },
                body: JSON.stringify(data),
            });
            const responseJSON = await response.json();
            if(responseJSON.status.toString() === "200"){
                
                const customData = {
                    phone: responseJSON.data.phone
                }
                setIsPhone(responseJSON.data.phone);

                localStorage.setItem('stored_details', JSON.stringify(customData));
                setSteps(3);
                setIsDisabledButton(false);
                setIsLoading(false);
                setDisableCreatePhone(true);

                setCusPhone(responseJSON.data.phone);
                setCusDetails(responseJSON.data);

                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);

            }else{
                if(responseJSON.status.toString() === "500"){
                    setShowErrMessages("error||" + responseJSON.required_fields);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                    return;
                }
                setShowErrMessages("error||" + responseJSON.message);
                setTimeout(() => {
                    setShowErrMessages(false);
                }, 3000);
            }
        } catch (error) {
            setShowErrMessages("error||" + error.message);
            setTimeout(() => {
                setShowErrMessages(false);
            }, 3000);
        }
    }


    const handleLogin = (data) => {
        setIsLogin(true);
    }


    return (
        <>
            {
                isLogin &&
                <form className="mt--5" onSubmit={handleSubmit(handleAuthLogin)}>
                    <div className="mainDiv">

                        <EnterPin register={register} errors={errors} />

                        <div className="all_btns mt-3 text-center">
                            <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                                Submit
                            </button>

                            <span className="closeBtn" onClick={() => setIsLogin(!isLogin)}>{'<'} Back</span>
                            
                            <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                        </div>
                    </div>
                </form>
            }

            {
                !isLogin &&
                <form className="mt--5" onSubmit={handleSubmit(handleCreateNumber)}>
                    <div className="mainDiv">
                        {
                            steps === 1 && (
                                !disableCreatePhone ?
                                <>
                                    <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                                    <div className="row radios_ radios_p mt--5 mb--20">
                                        <div className="col-6 text-end">
                                            <input {...register("sms_voice")} type="radio" value="||Self" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'self', setValue)}  />
                                            <span>SMS</span>
                                        </div>
                                        <div className="col-6 text-left">
                                            <input {...register("sms_voice")} type="radio" value="||Others" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'others', setValue)}  />
                                            <span>Voice</span>
                                        </div>
                                    </div>

                                    <input type="number" className="form-controls not_amount focusInput" placeholder="Enter phone number"  autoComplete="off"
                                        {...register("phone", { 
                                            required: "Please enter your phone number",
                                            pattern: {
                                                value: /^\d{11}$/,
                                                message: "Phone number must be at least 11 digits"
                                            }
                                        })}
                                    />
                                    {errors.phone && <label className="errors">{errors.phone?.message}</label>}
                                </>
                                :
                                
                                isPhone ? (
                                    <div className="text-center pt-10 pb-10">
                                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>Your Phone Number</p>
                                        <h2>{isPhone}</h2>
                                    </div>
                                ) : (
                                    <div className="text-center">Loading...</div>
                                )
                            )
                        }

                        {
                            steps === 2 &&
                            <>
                                <div className="text-center pt-5">
                                    <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>
                                    
                                    <h4 className="mb-10" style={{ fontWeight:'500', fontSize:'15px', lineHeight:'21px' }}>Confirm that this phone number <b>{inputDatas.phone}</b> is yours and cannot be edited.</h4>

                                    <p className="mb-10" style={{ fontWeight:'600', fontSize:'14px', lineHeight:'20px' }}>Enter the OTP code below from your mobile phone.</p>

                                    <input type="password" className="passwordInput focusInput" placeholder="Enter OTP"  autoComplete="off"
                                        {...register("code", { 
                                            required: "Please enter your OTP",
                                            pattern: {
                                                value: /^\d{6}$/,
                                                message: "OTP must be at least 6 digits"
                                            }
                                        })}
                                    />
                                    {errors.code && <label className="errors">{errors.code?.message}</label>}
                                </div>
                            </>
                        }

                        {
                            steps === 3 &&
                            <div className="successDiv text-center pt-10 mb-20">
                                <p className="mb-3" style={{ lineHeight:'20px', fontWeight:'600', fontSize:'15px' }}>Your phone number <b>{inputDatas.phone}</b> has been registered successfully!</p>
                                
                                <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                            </div>
                        }
                        

                        <div className="all_btns mt-10 text-center">
                            {
                                !disableCreatePhone && (
                                    <>
                                        <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                                            {steps === 2
                                            ?
                                            "Confirm Phone"
                                            :
                                            steps === 3 ? "Go To Main Menu" : "Next"
                                            }
                                        </button>

                                        <div><span className="closeBtn" onClick={handleLogin}>I have registered</span></div>
                                    </>
                                )
                            }

                            {
                                (steps > 1 && steps < 3) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                            }
                            <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                        </div>
                    </div>
                </form>
            }
        </>
    );
}