import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LeftNav from "./LeftNav";
import AirtimeDiv from "./AirtimeDiv";
import $ from 'jquery';
import { useForm } from "react-hook-form";
import CreatePin from "./CreatePin";
import Transfers from "./Transfers";
import DataDiv from "./DataDiv";
import CreatePhoneNumber from "./CreatePhoneNumber";
import CardlessWithdrawal from "./CardlessWithdrawal";
import SavedBeneficiary from "./SavedBeneficiary";
import ChangePin from "./ChangePin";
import TransactionLimit from "./TransactionLimit";
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import ContactUs from "./ContactUs";
import AccountBal from "./AccountBal";
import PayBills from "./PayBills";
import Enquiry from "./Enquiry";
import AcctOpen from "./AcctOpen";
import ClickCredit from "./ClickCredit";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container, Button, Alert } from 'react-bootstrap';
import PayAttitude from "./PayAttitude";
import BankServices from "./BankServices";
import GenerateOTP from "./GenerateOTP";
import MiniStatement from "./MiniStatement";
import LifeStyle from "./LifeStyle";


export default function MainScreen2(){
    
    const {register, handleSubmit, reset, getValues, setValue, formState: {errors, isValid, isSubmitting}} = useForm({mode: "onChange"});

    const {register: register1, handleSubmit: handleSubmit1, reset: reset1, getValues: getValues1, formState: {errors: errors1, isValid: isValid1, isSubmitting: isSubmitting1}} = useForm({mode: "onChange"});

    const {register: register2, handleSubmit: handleSubmit2, reset: reset2, setValue: setValue2, getValues: getValues2, formState: {errors: errors2, isValid: isValid2, isSubmitting: isSubmitting2}} = useForm({mode: "onChange"});

    const {register: register3, handleSubmit: handleSubmit3, reset: reset3, getValues: getValues3, formState: {errors: errors3, isValid: isValid3, isSubmitting: isSubmitting3}} = useForm({mode: "onChange"});

    const {register: register4, handleSubmit: handleSubmit4, reset: reset4, getValues: getValues4, setValue: setValue4, formState: {errors: errors4, isValid: isValid4, isSubmitting: isSubmitting4}} = useForm({mode: "onChange"});

    const {register: register5, handleSubmit: handleSubmit5, reset: reset5, getValues: getValues5, setValue: setValue5, formState: {errors: errors5, isValid: isValid5, isSubmitting: isSubmitting5}} = useForm({mode: "onChange"});

    const {register: register6, handleSubmit: handleSubmit6, reset: reset6, getValues: getValues6, setValue: setValue6, formState: {errors: errors6, isValid: isValid6, isSubmitting: isSubmitting6}} = useForm({mode: "onChange"});

    const {register: register7, handleSubmit: handleSubmit7, reset: reset7, getValues: getValues7, setValue: setValue7, formState: {errors: errors7, isValid: isValid7, isSubmitting: isSubmitting7}} = useForm({mode: "onChange"});
    
    const {register: register8, handleSubmit: handleSubmit8, reset: reset8, getValues: getValues8, setValue: setValue8, formState: {errors: errors8, isValid: isValid8, isSubmitting: isSubmitting8}} = useForm({mode: "onChange"});

    const { register: register9, reset: reset9, setValue: setValue9, handleSubmit: handleSubmit9, formState: {errors: errors9, isValid: isValid9, isSubmitting: isSubmitting9}} = useForm({mode: "onChange"});
    
    const {register: register10, handleSubmit: handleSubmit10, reset: reset10, getValues: getValues10, setValue: setValue10, formState: {errors: errors10, isValid: isValid10, isSubmitting: isSubmitting10}} = useForm({mode: "onChange"});

    const {register: register11, handleSubmit: handleSubmit11, reset: reset11, setValue: setValue11, formState: {errors: errors11, isValid: isValid11, isSubmitting: isSubmitting11}} = useForm({mode: "onChange"});

    const {register: register12, handleSubmit: handleSubmit12, reset: reset12, setValue: setValue12, formState: {errors: errors12, isValid: isValid12, isSubmitting: isSubmitting12}} = useForm({mode: "onChange"});

    const {register: register13, handleSubmit: handleSubmit13, reset: reset13, setValue: setValue13, formState: {errors: errors13, isValid: isValid13, isSubmitting: isSubmitting13}} = useForm({mode: "onChange"});

    const {register: register14, handleSubmit: handleSubmit14, reset: reset14, getValues: getValues14, setValue: setValue14, formState: {errors: errors14, isValid: isValid14, isSubmitting: isSubmitting14}} = useForm({mode: "onChange"});

    const {register: register15, handleSubmit: handleSubmit15, reset: reset15, getValues: getValues15, setValue: setValue15, formState: {errors: errors15, isValid: isValid15, isSubmitting: isSubmitting15}} = useForm({mode: "onChange"});

    const {register: register16, handleSubmit: handleSubmit16, reset: reset16, setValue: setValue16, formState: {errors: errors16, isValid: isValid16, isSubmitting: isSubmitting16}} = useForm({mode: "onChange"});

    const {register: register17, handleSubmit: handleSubmit17, reset: reset17, setValue: setValue17, formState: {errors: errors17, isValid: isValid17, isSubmitting: isSubmitting17}} = useForm({mode: "onChange"});

    const {register: register18, handleSubmit: handleSubmit18, reset: reset18, setValue: setValue18, formState: {errors: errors18, isValid: isValid18, isSubmitting: isSubmitting18}} = useForm({mode: "onChange"});

    // const {register: register19, handleSubmit: handleSubmit19, reset: reset19, setValue: setValue19, formState: {errors: errors19, isValid: isValid19, isSubmitting: isSubmitting19}} = useForm({mode: "onChange"});

    const createPinRef = useRef(null);
    
    const mtnPhoneCodes = ['0703', '0706', '0803', '0806', '0810', '0813', '0814', '0816', '0903', '0906', '0913'];
    const airtelPhoneCodes = ['0701', '0708', '0802', '0808', '0812', '0902', '0907', '0901', '0912'];
    const gloPhoneCodes = ['0705', '0805', '0807', '0811', '0815', '0905'];
    const etisalatPhoneCodes = ['0809', '0817', '0818', '0908', '0909'];

    const [isAirtime, setIsAirtime] = useState(true);
    const [isDatas, setIsDatas] = useState(true);
    const [isCardless, setIsCardless] = useState(true);
    const [isEnterPin, setIsEnterPin] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isCreateNumber, setIsCreateNumber] = useState(false);
    const [isCreatePin, setIsCreatePin] = useState(false);
    const [isChangePin, setIsChangePin] = useState(false);
    const [isEnquiry, setIsEnquiry] = useState(false);
    const [isAcctOpen, setIsAcctOpen] = useState(false);
    const [isClickCredit, setIsClickCredit] = useState(false);
    const [isTransfer, setIsTransfer] = useState(false);
    const [titles, setTitles] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [steps, setSteps] = useState(1);
    const [yesMoney, setYesMoney] = useState(false);
    const [mainMenu, setMainMenu] = useState(true);
    const [isSettings, setIsSettings] = useState(false);
    const [isCounter, setIsCounter] = useState(true);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [getAcctNumber, setGetAcctNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [isSuccess1, setIsSuccess1] = useState(false);
    const [showMessages, setShowMessages] = useState(true);
    const [disableCreatePin, setDisableCreatePin] = useState(false);
    const [disableCreatePhone, setDisableCreatePhone] = useState(false);
    const [isTransactionLimit, setIsTransactionLimit] = useState(false);
    const [isContactPage, setIsContactPage] = useState(false);
    const [isAcctBal, setIsAcctBal] = useState(false);
    const [isPayBills, setIsPayBills] = useState(false);
    const [titleName, setTitleName] = useState('');
    const [isPlaceholder, setIsPlaceholder] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [isMorePages, setIsMorePages] = useState(1);
    const [showMessage, setShowMessage] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isPayAttitude, setIsPayAttitude] = useState(false);
    const [isBankServices, setIsBankServices] = useState(false);
    const [isGenOTP, setIsGenOTP] = useState(false);
    const [isMini, setIsMini] = useState(false);
    const [isLifeStyle, setIsLifeStyle] = useState(false);
    const [direction, setDirection] = useState('forward');
    

    const totalSteps = 3; // Total number of steps


    // const toggleVisibility = () => {
    //     setIsVisible(prev => !prev);
    // };



    useEffect(() => {
        let inactivityTimeout;
    
        const resetTimeout = () => {
            clearTimeout(inactivityTimeout);
            inactivityTimeout = setTimeout(logout, 5 * 60 * 1000); // 5 minutes in milliseconds
        };
    
        const logout = () => {
            setIsLoggedIn(false);
            localStorage.setItem('stored_details', null);
            window.location.reload();
        };
    
        const handleActivity = () => {
            resetTimeout();
        };
    
        // Add event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
    
        resetTimeout();
    
        // Clean up event listeners on component unmount
        return () => {
          clearTimeout(inactivityTimeout);
          window.removeEventListener('mousemove', handleActivity);
          window.removeEventListener('keydown', handleActivity);
        };
    }, []);
    
    
    
    // const [isOverlay, setIsOverlay] = useState(true); // false is no phone
    const [isDisabledButton, setIsDisabledButton] = useState(true);
    const [acctNo, setAcctNo] = useState('');
    
    const [isSuccessInput, setIsSuccessInput] = useState(bankName === "" ? false : true);
    
    
    const [inputDatas, setInputDatas] = useState({});
    const [showErrMessages, setShowErrMessages] = useState(false);
    
    const [codes, setCodes] = useState('buy_airtime');
    const [refs1, setRefs1] = useState('');
    const [confirmDetls, setConfirmDetls] = useState('');
    const [getNetworks, setGetNetworks] = useState('');
    const [successMsg, setSuccessMsg] = useState(null);
    const [cusPhone, setCusPhone] = useState('');
    const [cusDetails, setCusDetails] = useState({});
    const [ntwkDataBundles, setNtwkDataBundles] = useState([]);
    


    const getAuthDetails = async () => {
        const storedPhone = JSON.parse(localStorage.getItem('stored_details'));
        // console.log(storedPhone);
        const datas = {
            'phone': storedPhone ? storedPhone.phone : null
        }
        try {
            const response = await fetch(API_ROUTES.GET_AUTH_DETAILS, {
                method: "POST",
                headers: { ...headers },
                body: JSON.stringify(datas),
            });
            const responseJSON = await response.json();

            // console.log(responseJSON);

            if(responseJSON.status.toString() === "200"){
                setIsDisabledButton(false);
                setCusPhone(storedPhone.phone);
                setCusDetails(responseJSON.data);
                setIsLoading(false);

                if(responseJSON.data.pin !== null && responseJSON.data.pin.length > 10){
                    setDisableCreatePin(true);
                }
                if(responseJSON.data.phone !== null && responseJSON.data.phone.length > 9){
                    setDisableCreatePhone(true);
                }
            }else{
                setIsDisabledButton(true);
                openDivHandler('create_number', 'create number');
                setIsLoading(false);
                // console.log('SSSS');
            }
        } catch (error) {
            console.log(error);
            setIsDisabledButton(true);
            openDivHandler('create_number', 'create number');
            setIsLoading(false);
        }
    }


    

    

    useEffect(() => {
        const min = Math.pow(10, 14);
        const max = Math.pow(10, 15) - 1;
        const randomNumbers = Math.floor(Math.random() * (max - min + 1)) + min;
        setRefs1(randomNumbers);
        
        getAuthDetails();
    }, []);


    const logout = () => {
        localStorage.setItem('stored_details', null);
        window.location.reload();
    }



    const openDivHandler = (code, title) => {
        // console.log(code);
        const min = Math.pow(10, 14);
        const max = Math.pow(10, 15) - 1;
        const randomNumbers = Math.floor(Math.random() * (max - min + 1)) + min;

        const storedPhone = JSON.parse(localStorage.getItem('stored_details'));
        if(storedPhone){
            setTimeout(() => {
                setValue('phone', cusDetails.phone || (storedPhone.phone));
                setValue4('phone', cusDetails.phone || (storedPhone.phone));
            }, 300);
        }

        const customInputs = document.querySelectorAll('.customInputs input');
        customInputs.forEach(input => input.value = '');
        setCodes(code);
        setRefs1(randomNumbers);

        if(code === "buy_airtime"){
            setTitles(`To buy airtime, kindly input amount (N50 - N10,000)`);
        }else if(code === "buy_data"){
            setTitles("To buy data, kindly select your preferred package and continue button");
        }else if(code === "transfer_uba"){
            setTitles("To transfer to UBA, enter amount to be transferred below");
        }else if(code === "transfer_others"){
            setTitles("To transfer to other banks, enter amount to be transferred below");
        }else if(code === "pay_bills"){
            setTitles("To pay bills, kindly select the utility and package to continue");
        }else if(code === "cardless"){
            setTitles("Withdraw or send any amount from the ATM without using a physical card");
        }else if(code === "create_pin"){
            setTitles("To create your transaction PIN, enter a 6-digit number and continue");
        }else if(code === "create_number"){
            setTitles("Register your phone number here. This registration can only be done once and cannot be editable");
        }else if(code === "open_beneficiary"){
            setTitles("These are your saved beneficiaries");
        }else if(code === "change_pin"){
            setTitles("Enter the details below to continue");
        }else if(code === "transaction_limit"){
            setTitles("Set your transaction limit here");
        }else if(code === "contact_us"){
            setTitles("Do you have any challenge, enquiry or suggestion, we are always here to assist");
        }else if(code === "acct_balance"){
            setTitles("Carrier Info");
        }else if(code === "enquiries"){
            setTitles("Want to make Enquiries or use our support?");
        }else if(code === "acct_opening"){
            setTitles("Open a UBA account with us with ease and seamless");
        }else if(code === "click_credit"){
            setTitles("Apply for loan instantly at a low interest rate with no hidden charges.");
        }else if(code === "pay_attitude"){
            setTitles("Receive or send money to a phone number and play games");
        }else if(code === "mini_statement"){
            setTitles("View your mini statement of account with ease.");
        }else if(code === "generate_otp"){
            setTitles("Generate a new OTP for your transaction");
        }else if(code === "bank_services"){
            setTitles("Block your debit card or freeze your online transactions");
        }else if(code === "lifestyle"){
            setTitles("Subscribe to UBA lifestyle features and enjoy amazing discounts!");
        }
        
        
        setTimeout(() => {
            $('.customInputs input.focusInput').focus();
        }, 600);
        $('.inner_house button').removeClass('active');
        $(this).addClass('active');

        $('.overlays').fadeIn('fast');
        
        $('.showModal').show().animate({right: '10px'}, 1);


        setIsAirtime(false);
        setIsEnterPin(false);
        setIsConfirm(false);
        setIsSuccess(false);
        setIsCreatePin(false);
        setIsTransactionLimit(false);
        setIsChangePin(false);
        setIsPayAttitude(false);
        setIsBankServices(false);
        setIsGenOTP(false);
        setIsMini(false);
        setIsLifeStyle(false);
        setIsEnquiry(false);
        setIsTransfer(false);
        setIsDatas(false);
        setIsCreateNumber(false);
        setIsCardless(false);
        setIsBeneficiary(false);
        setIsContactPage(false);
        setIsAcctBal(false);
        setIsPayBills(false);
        setIsAcctOpen(false);
        setIsClickCredit(false);

        if(code === "buy_airtime"){
            setValue('phone', cusDetails.phone);
            setIsAirtime(true);
        }
        if(code === "buy_data"){
            setValue4('phone', cusDetails.phone);
            setIsDatas(true);
        }
        if(code === "create_pin"){
            setIsCreatePin(true);
        }
        if(code === "change_pin"){
            setIsChangePin(true);
        }
        if(code === "pay_attitude"){
            setIsPayAttitude(true);
        }
        if(code === "bank_services"){
            setIsBankServices(true);
        }
        if(code === "generate_otp"){
            setIsGenOTP(true);
        }
        if(code === "mini_statement"){
            setIsMini(true);
        }
        if(code === "lifestyle"){
            setIsLifeStyle(true);
        }
        if(code === "transaction_limit"){
            setIsTransactionLimit(true);
        }
        if(code === "transfer_uba"){
            setIsTransfer(true);
        }
        if(code === "pay_bills"){
            setIsPayBills(true);
        }
        if(code === "create_number"){
            setIsCreateNumber(true);
        }
        if(code === "open_beneficiary"){
            setIsBeneficiary(true);
        }
        if(code === "cardless"){
            setIsCardless(true);
        }
        if(code === "contact_us"){
            setIsContactPage(true);
        }
        if(code === "acct_balance"){
            setIsAcctBal(true);
        }
        if(code === "enquiries"){
            setIsEnquiry(true);
        }
        if(code === "acct_opening"){
            setIsAcctOpen(true);
        }
        if(code === "click_credit"){
            setIsClickCredit(true);
        }
        
    }


    const closeHandler = (codes) => {
        $('.inner_house button').removeClass('active');
        $('.overlays').fadeOut('fast');
        $('.showModal').show().animate({right: '-1000px'}, 1);

        setTimeout(() => {
            $('.inner_house button.defaults').addClass('active');
        }, 200);

        setTimeout(() => {
            setIsAirtime(true);
            setIsEnterPin(false);
            setIsConfirm(false);
            setIsSuccess(false);
            setIsCreatePin(false);
            setIsTransactionLimit(false);
            setIsChangePin(false);
            setIsPayAttitude(false);
            setIsBankServices(false);
            setIsGenOTP(false);
            setIsMini(false);
            setIsLifeStyle(false);
            setIsTransfer(false);
            setIsDatas(false);
            setIsCreateNumber(false);
            setIsBeneficiary(false);
            setIsCardless(false);
            setIsContactPage(false);
            setIsAcctBal(false);
            setIsPayBills(false);
            setIsEnquiry(false);
            setIsAcctOpen(false);
            setIsClickCredit(false);
            setGetNetworks('');

            reset();
            reset1();
            reset2();
            reset3();
            reset4();
            reset5();
            reset6();
            reset7();
            reset8();
            reset9();
            reset10();
            reset11();
            reset12();
            reset13();
            reset14();
            reset15();
            reset16();
            reset17();
            reset18();
            // reset19();
            // reset20();
            setIsSuccess1(!isSuccess1);
            setShowMessages(false);

            setCodes(codes);
            setSteps(1);
        }, 600);
    }

    const cmdNext = (screen, codes, data) => {
        setIsAirtime(false);
        setIsEnterPin(false);
        setIsConfirm(false);
        setIsSuccess(false);
        setIsCreatePin(false);
        setIsTransactionLimit(false);
        setIsChangePin(false);
        setIsPayAttitude(false);
        setIsBankServices(false);
        setIsGenOTP(false);
        setIsMini(false);
        setIsLifeStyle(false);
        setIsTransfer(false);
        setIsDatas(false);
        setIsCreateNumber(false);
        setIsBeneficiary(false);
        setIsCardless(false);
        setIsContactPage(false);
        setIsAcctBal(false);
        setIsPayBills(false);
        setIsEnquiry(false);
        setIsAcctOpen(false);
        setIsClickCredit(false);

        if(screen === "enter_pin"){
            setIsEnterPin(true);

        }else if(screen === "confirm_screen"){
            setInputDatas(data);
            setIsConfirm(true);

        }else if(screen === "create_pin"){
            setIsCreatePin(true);

        }else if(screen === "change_pin"){
            setIsChangePin(true);

        }else if(screen === "pay_attitude"){
            setIsPayAttitude(true);

        }else if(screen === "bank_services"){
            setIsBankServices(true);

        }else if(screen === "generate_otp"){
            setIsGenOTP(true);

        }else if(screen === "mini_statement"){
            setIsMini(true);

        }else if(screen === "lifestyle"){
            setIsLifeStyle(true);
        
        }else if(screen === "transaction_limit"){
            setIsTransactionLimit(true);

        }else if(screen === "success_screen"){
            setIsSuccess(true);

        }else if(screen === "open_beneficiary"){
            setIsBeneficiary(true);

        }else if(screen === "contact_us"){
            setIsContactPage(true);

        }else if(screen === "acct_balance"){
            setIsAcctBal(true);

        }else if(screen === "pay_bills"){
            setIsPayBills(true);

        }else if(screen === "enquiries"){
            setIsEnquiry(true);

        }else if(screen === "acct_opening"){
            setIsAcctOpen(true);

        }else if(screen === "click_credit"){
            setIsClickCredit(true);
        }
        

    }



    const backHandler = (codes) => {
        setIsAirtime(false);
        setIsEnterPin(false);
        setIsConfirm(false);
        setIsSuccess(false);
        setIsCreatePin(false);
        setIsTransactionLimit(false);
        setIsChangePin(false);
        setIsPayAttitude(false);
        setIsBankServices(false);
        setIsGenOTP(false);
        setIsMini(false);
        setIsLifeStyle(false);
        setIsDatas(false);
        setIsCreateNumber(false);
        setIsCardless(false);
        setIsBeneficiary(false);
        setIsContactPage(false);
        setIsAcctBal(false);
        setIsPayBills(false);
        setIsEnquiry(false);
        setIsAcctOpen(false);
        setIsClickCredit(false);

        setCodes(codes);

        if(codes === "buy_airtime"){
            setIsAirtime(true);

        }else if(codes === "buy_data"){
            setIsDatas(true);

        }else if(codes === "pay_bills"){
            setIsPayBills(true);

        }else if(codes === "confirm_screen"){
            setIsConfirm(true);

        }else if(codes === "create_pin"){
            setIsCreatePin(true);

        }else if(codes === "change_pin"){
            setIsChangePin(true);

        }else if(codes === "pay_attitude"){
            setIsPayAttitude(true);

        }else if(codes === "bank_services"){
            setIsBankServices(true);

        }else if(codes === "generate_otp"){
            setIsGenOTP(true);

        }else if(codes === "mini_statement"){
            setIsMini(true);

        }else if(codes === "lifestyle"){
            setIsLifeStyle(true);

        }else if(codes === "transaction_limit"){
            setIsTransactionLimit(true);

        }else if(codes === "create_number"){
            setIsCreateNumber(true);

        }else if(codes === "cardless"){
            setIsCardless(true);
        
        }else if(codes === "open_beneficiary"){
            setIsBeneficiary(true);

        }else if(codes === "contact_us"){
            setIsContactPage(true);

        }else if(codes === "acct_balance"){
            setIsAcctBal(true);

        }else if(codes === "enquiries"){
            setIsEnquiry(true);

        }else if(codes === "acct_opening"){
            setIsAcctOpen(true);

        }else if(codes === "click_credit"){
            setIsClickCredit(true);
        }
        
    }



    const handleCheckboxClick = (e, captions, setValue) => {
        const isChecked = e.target.value;
        const ids = isChecked.split('||')[0];
        const names = isChecked.split('||')[1];

        const storedPhone = JSON.parse(localStorage.getItem('stored_details'));

        if(names === "Self"){
            setValue('phone', cusDetails.phone || (storedPhone && storedPhone.phone));
            setIsPlaceholder("Enter your phone number");

        }else if(names === "Others"){
            setValue('phone', '');
            setIsPlaceholder("Enter receipient phone");

        }else{
            setValue('phone', '');
            setIsPlaceholder("Enter receipient phone");
        }
        setTitleName(names);
    }



    // const hashPassword = async (password) => {
    //     try {
    //       const salt = await bcrypt.genSalt(10);
    //       const hashedPassword = await bcrypt.hash(password, salt);
    //       return hashedPassword;
    //     } catch (error) {
    //       console.error('Error hashing password:', error);
    //       throw error;
    //     }
    // }

    // const plainPassword = 'motolani';
    // hashPassword(plainPassword)
    // .then((hashedPassword) => {
    //     console.log('Hashed password:', hashedPassword);
    // })
    // .catch((error) => {
    //     console.error('Error:', error);
    // });


    // const laravelHash = '$2y$10$1kFrTMDtxkwaq8T2K0SkF.nmEQVQN2yJ5zmNvfYyXW5EaEqL6A89y';

    // const submittedPassword = 'motolani';

    // bcrypt.compare(submittedPassword, laravelHash, function(err, result) {
    //     if (err) {
    //         console.error('Error comparing passwords:', err);
    //         return;
    //     }
    //     if (result) {
    //         console.log('Password matches!');
    //     } else {
    //         console.log('Password does not match.');
    //     }
    // });



    const listOfBanks = [
        { id: 1, name: "Access Bank PLC" },
        { id: 2, name: "United Bank of Africa (UBA)" },
        { id: 3, name: "First Bank PLC" },
        { id: 4, name: "United Bank of Africa (UBA)" },
        { id: 5, name: "First Bank PLC" },
        { id: 6, name: "Guarantee Trust Bank" },
        { id: 7, name: "Pinspay" },
        { id: 8, name: "Guarantee Trust Bank" },
        { id: 9, name: "Access Bank PLC" },
    ];

    const withdrawalTypes = [
        { id: 1, name: "Self Cardless Withdrawal", description: "Withdraw any amount from the ATM without using a physical card" },
        { id: 2, name: "Beneficiary Cardless Withdrawal", description: "Send money to another person that he can directly withdraw from any ATM" },
        { id: 3, name: "To Beneficiary Mobile Number", description: "Send money to another person that he can deposit to any bank account using the eChannel" }
    ];

    const beneficiaries = [
        { id: 1, name: "Belinda Anthony", description: "7066767986 (Opay)" },
        { id: 2, name: "Femi Kuti", description: "0695589998 (Access Bank)" },
        { id: 3, name: "Temitope Olatunji", description: "9938382933 (Zenith Bank PLC)" },
        { id: 4, name: "Chikodi Amobi", description: "4993984843 (United Bank of Africa UBA)" },
        { id: 5, name: "Nelson Amos", description: "3994949039 (United Bank of Africa UBA)" },
        { id: 6, name: "Musa Yusuf", description: "0823443842 (Opay)" },
    ];

    
    const billTypes = [
        { id: 1, name: "Cable TV", description: "Watch some latest channels when you subscribe with ease" },
        { id: 2, name: "Electricity", description: "Need power? Get your meter recharged within some seconds" },
        { id: 3, name: "Education", description: "Pay school fees, Jamb, WAEC through your bank seamlessly" },
        { id: 4, name: "Betting/Lottery", description: "Win massive games on sport bet or lottery" },
        { id: 5, name: "Tolls", description: "Pay toll fee seamlessly" },
        { id: 6, name: "Airlines", description: "Book your flights right at your pace" },
        { id: 7, name: "Water", description: "Pay for your water consumption" },
        { id: 8, name: "Waste", description: "Pay for your waste bin monthly" },
        { id: 9, name: "Internet Data", description: "Get huge fast speed internet connectivity on your modems" },
        { id: 10, name: "Churches", description: "Pay your tithes, projects and other church bills with ease" },
    ];


    
    useEffect(() => {
      setTimeout(() => {
        setYesMoney(true);
        $('.tooltip1').show();
      }, 400);


      setTimeout(function() {
        let tooltipText = document.querySelector('.tooltip-text1');
        if (tooltipText) {
            tooltipText.classList.add('blink');
        }
      }, 1000);

      setTimeout(function() {
        let tooltipText1 = document.querySelector('.tooltip-text1');
        if (tooltipText1) {
            tooltipText1.classList.remove('blink');
        }
      }, 1400);

      setTimeout(function() {
        $('.tooltip-text1').fadeOut(800);
      }, 3000);

    }, []);


    const toggleSettings = () => {
        // if (!disableCreatePhone && !isLoading) {
            setIsMorePages((prevStep) => {
              const nextStep = prevStep === 3 ? 1 : prevStep + 1;
              setDirection(nextStep > prevStep ? 'forward' : 'backward');
              return nextStep;
            });
        // }
    };



    const goToMain = (morePages) => {
        setIsSettings(true);
        setIsMorePages(morePages);
    }



    const closeErrorDiv = () => {
        setShowErrMessages(false);
    }

    const getClassName = (isErrStatus) => {
        if (isErrStatus === "success") return 'success_msg';
        if (isErrStatus === "processing") return 'process_msg';
        return '';
    }





    return (
        <>
            <div className="container p-xs-0">
                <div className="row align-items-center">

                    <LeftNav />
                    
                    <div className="col-lg-6 col-md-12">
                        <div className={`mockup_phone1 ${yesMoney && 'mockup_phone_yesmoney'}`}>
                            
                            <div className="mockup_phone no_scroll">
                                {
                                    showErrMessages && (() => {
                                        const isErrStatus = showErrMessages.split('||')[0];
                                        const isErrMsg = showErrMessages.split('||')[1];
                                        return (
                                            <>
                                                <div className="overlay_error" onClick={closeErrorDiv}></div>
                                                <div className={`error_msg ${getClassName(isErrStatus)}`}>{isErrMsg}</div>

                                                {/* <div className={`error_msg ${isErrStatus === "success" && 'success_msg' isErrStatus === "processing" && 'process_msg'}`}>{isErrMsg}</div> */}
                                            </>
                                        );
                                    })()
                                }
                                
                                <div className={`${!isLoading && 'onboarding-section onboarding-section2'} pt-0`} id="onboardingBody">
                                    <div className="swiper onboarding onboarding2">
                                        
                                        <div className="swiper onboarding">
                                            
                                            <div className="text-center header_background mt--20">
                                                {
                                                    isDisabledButton && (
                                                        <>
                                                            <div className="tooltip1">
                                                                <span className="tooltip-text1">Click here to register your phone number</span>
                                                            </div>
                                                            <div className="overlays1"></div>
                                                        </>
                                                    )
                                                }

                                                <div className="inner_bck" style={{ position:'relative', zIndex:100 }}>
                                                    <img className="img-logo1" src="../assets/images/uba-logo-white1.png" alt="UBA Logo" />
                                                    <p className="mt-10" style={{ color:'#ddd', fontSize: '22px', fontWeight: 600 }}>Mobile Banking</p>
                                
                                                    <div className="offset-2 col-8">
                                                        <div className="row mt-10 mb--5">
                                                            <div className="col-4 circles">
                                                                <Link
                                                                    style={{ opacity: isLoading ? 0.4 : 1 }}
                                                                    onClick={() => !isLoading && openDivHandler('create_number', 'create number')}
                                                                >
                                                                    <i className={`fa fa-phone myNumber ${!disableCreatePhone && 'myNumberAnimate' }`}></i>
                                                                    <p>MyNumber</p>
                                                                </Link>
                                                            </div>

                                                            <div className="col-4 circles circles1">
                                                                <Link style={{ opacity: (isDisabledButton || isLoading) ? 0.4 : 1 }} onClick={() => (disableCreatePhone || isLoading) && openDivHandler('transfer_uba', 'transfer to UBA')}>
                                                                    <i className="fa fa-wallet"></i>
                                                                    <p>Transfer</p>
                                                                </Link>
                                                            </div>

                                                            <div className="col-4 circles">
                                                                <Link style={{ opacity: (isDisabledButton || isLoading) ? 0.4 : 1 }} onClick={() => (disableCreatePhone || isLoading) && toggleSettings()}>
                                                                    <i className={`fa ${isMorePages === 3 ? "fa-chevron-left" : "fa-chevron-right"}`}></i>
                                                                    <p>{isMorePages === 3 ? "Home" : "More"}</p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                
                                
                                            {
                                                isLoading ? 
                                                <div className="mt-50">
                                                    <LoadingSpinner /> 
                                                </div>
                                                :
                                                <div className="onboarding-wrapper__">
                                                    <div className="overlays" onClick={closeHandler}></div>

                                                    <div className="showModal customInputs">

                                                        {
                                                            isCreateNumber &&
                                                                <CreatePhoneNumber closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register3} handleSubmit={handleSubmit3} isSubmitting={isSubmitting3} isValid={isValid3} errors={errors3} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setInputDatas={setInputDatas} setShowErrMessages={setShowErrMessages} setIsDisabledButton={setIsDisabledButton} setIsLoading={setIsLoading} disableCreatePhone={disableCreatePhone} setDisableCreatePhone={setDisableCreatePhone} cusPhone={cusPhone} createPinRef={createPinRef} setCusDetails={setCusDetails} setCusPhone={setCusPhone} />
                                                        }

                                                        {
                                                            isCreatePin &&
                                                                <CreatePin closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register1} handleSubmit={handleSubmit1} isSubmitting={isSubmitting1} isValid={isValid1} errors={errors1} isLoading={isLoading} getValues={getValues1} steps={steps} setSteps={setSteps} codes={codes} setShowErrMessages={setShowErrMessages} setIsDisabledButton={setIsDisabledButton} cusPhone={cusPhone} setDisableCreatePin={setDisableCreatePin} setCusDetails={setCusDetails} setDisableCreatePhone={setDisableCreatePhone} />
                                                        }

                                                        {
                                                            isChangePin &&
                                                                <ChangePin closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register7} handleSubmit={handleSubmit7} isSubmitting={isSubmitting7} isValid={isValid7} errors={errors7} isLoading={isLoading} getValues={getValues7} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages}setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue7} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} />
                                                        }

                                                        {
                                                            isTransfer &&
                                                                <Transfers closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register2} isSubmitting={isSubmitting2} isValid={isValid2} errors={errors2} isLoading={isLoading} getValues={getValues2} handleCheckboxClick={handleCheckboxClick} steps={steps} listOfBanks={listOfBanks} refs1={refs1} inputDatas={inputDatas} setSteps={setSteps} openDivHandler={openDivHandler} bankName={bankName} isSuccess1={isSuccess1} setIsSuccess1={setIsSuccess1} showMessages={showMessages} setShowMessages={setShowMessages} setValue={setValue2} acctNo={acctNo} setInputDatas={setInputDatas} handleSubmit={handleSubmit2} codes={codes} />
                                                        }

                                                        {
                                                            isAirtime &&
                                                            <AirtimeDiv closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register} isSubmitting={isSubmitting} isValid={isValid} errors={errors} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setGetNetworks={setGetNetworks} setValue={setValue} cusDetails={cusDetails} setInputDatas={setInputDatas} mtnPhoneCodes={mtnPhoneCodes} airtelPhoneCodes={airtelPhoneCodes} gloPhoneCodes={gloPhoneCodes} etisalatPhoneCodes={etisalatPhoneCodes} handleSubmit={handleSubmit}  setShowErrMessages={setShowErrMessages} isPlaceholder={isPlaceholder} />
                                                        }

                                                        {
                                                            isDatas &&
                                                            <DataDiv closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register4} isSubmitting={isSubmitting4} isValid={isValid4} errors={errors4} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setGetNetworks={setGetNetworks} setValue={setValue4} cusDetails={cusDetails} setInputDatas={setInputDatas} mtnPhoneCodes={mtnPhoneCodes} airtelPhoneCodes={airtelPhoneCodes} gloPhoneCodes={gloPhoneCodes} etisalatPhoneCodes={etisalatPhoneCodes} handleSubmit={handleSubmit4}  setShowErrMessages={setShowErrMessages} />
                                                        }

                                                        {
                                                            isCardless &&
                                                            <CardlessWithdrawal closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register5} isSubmitting={isSubmitting5} isValid={isValid5} errors={errors5} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue5} withdrawalTypes={withdrawalTypes} handleSubmit={handleSubmit5} setInputDatas={setInputDatas} />
                                                        }

                                                        {
                                                            isBeneficiary &&
                                                            <SavedBeneficiary closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register6} isSubmitting={isSubmitting6} isValid={isValid6} errors={errors6} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue6} beneficiaries={beneficiaries} setIsTransfer={setIsTransfer} setIsBeneficiary={setIsBeneficiary} setTitles={setTitles} setCodes={setCodes} setInputDatas={setInputDatas} setBankName={setBankName} setIsSuccess1={setIsSuccess1} handleSubmit={handleSubmit6} setAcctNo={setAcctNo} setShowMessages={setShowMessages} />
                                                        }

                                                        {
                                                            isTransactionLimit &&
                                                            <TransactionLimit closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register8} isSubmitting={isSubmitting8} isValid={isValid8} errors={errors8} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} setValue={setValue8} cusPhone={cusPhone} setShowErrMessages={setShowErrMessages} handleSubmit={handleSubmit8} cusDetails={cusDetails} setInputDatas={setInputDatas} setCusDetails={setCusDetails} />
                                                        }

                                                        {/* {
                                                            isContactPage &&
                                                            <ContactUs closeHandler={closeHandler} titles={titles} steps={steps} setSteps={setSteps} setShowErrMessages={setShowErrMessages} />
                                                        } */}

                                                        {
                                                            isAcctBal &&
                                                            <AccountBal closeHandler={closeHandler} titles={titles} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} cusDetails={cusDetails} setValue={setValue9} handleSubmit={handleSubmit9} register={register9} isSubmitting={isSubmitting9} isValid={isValid9} errors={errors9} codes={codes} setShowErrMessages={setShowErrMessages} />
                                                        }
                                                        
                                                        {
                                                            isPayBills &&
                                                            <PayBills closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} refs1={refs1} register={register10} isSubmitting={isSubmitting10} isValid={isValid10} errors={errors10} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue10} reset={reset10} billTypes={billTypes} handleSubmit={handleSubmit10} setInputDatas={setInputDatas} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} titleName={titleName} setTitleName={setTitleName} />
                                                        }

                                                        {
                                                            isEnquiry &&
                                                            <Enquiry closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} register={register11} isSubmitting={isSubmitting11} isValid={isValid11} errors={errors11} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue11} handleSubmit={handleSubmit11} setInputDatas={setInputDatas} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} titleName={titleName} setTitleName={setTitleName} openDivHandler={openDivHandler} />
                                                        }
                                                        
                                                        {
                                                            isAcctOpen &&
                                                            <AcctOpen closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} register={register12} isSubmitting={isSubmitting12} isValid={isValid12} errors={errors12} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue12} handleSubmit={handleSubmit12} setInputDatas={setInputDatas} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} titleName={titleName} setTitleName={setTitleName} openDivHandler={openDivHandler} />
                                                        }

                                                        {
                                                            isClickCredit &&
                                                            <ClickCredit closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} codes={codes} register={register13} isSubmitting={isSubmitting13} isValid={isValid13} errors={errors13} handleCheckboxClick={handleCheckboxClick} isLoading={isLoading} steps={steps} setSteps={setSteps} inputDatas={inputDatas} getNetworks={getNetworks} setValue={setValue13} handleSubmit={handleSubmit13} setInputDatas={setInputDatas} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} titleName={titleName} setTitleName={setTitleName} openDivHandler={openDivHandler} />
                                                        }

                                                        {
                                                            isPayAttitude &&
                                                            <PayAttitude closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register14} handleSubmit={handleSubmit14} isSubmitting={isSubmitting14} isValid={isValid14} errors={errors14} isLoading={isLoading} getValues={getValues14} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue14} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} isSuccess1={isSuccess1} setShowMessages={setShowMessages} />
                                                        }

                                                        {
                                                            isBankServices &&
                                                            <BankServices closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register15} handleSubmit={handleSubmit15} isSubmitting={isSubmitting15} isValid={isValid15} errors={errors15} isLoading={isLoading} getValues={getValues15} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue15} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} isSuccess1={isSuccess1} setShowMessages={setShowMessages} />
                                                        }

                                                        {
                                                            isGenOTP &&
                                                            <GenerateOTP closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register16} handleSubmit={handleSubmit16} isSubmitting={isSubmitting16} isValid={isValid16} errors={errors16} isLoading={isLoading} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue16} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} isSuccess1={isSuccess1} setShowMessages={setShowMessages} />
                                                        }

                                                        {
                                                            isMini &&
                                                            <MiniStatement closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register17} handleSubmit={handleSubmit17} isSubmitting={isSubmitting17} isValid={isValid17} errors={errors17} isLoading={isLoading} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue17} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} isSuccess1={isSuccess1} setShowMessages={setShowMessages} />
                                                        }

                                                        {
                                                            isLifeStyle &&
                                                            <LifeStyle closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} backHandler={backHandler} register={register18} handleSubmit={handleSubmit18} isSubmitting={isSubmitting18} isValid={isValid18} errors={errors18} isLoading={isLoading} steps={steps} setSteps={setSteps} cusPhone={cusPhone} codes={codes} setShowErrMessages={setShowErrMessages} cusDetails={cusDetails} setCusDetails={setCusDetails} handleCheckboxClick={handleCheckboxClick} setValue={setValue18} inputDatas={inputDatas} setInputDatas={setInputDatas} setIsSuccess1={setIsSuccess1} setCusPhone={setCusPhone} isSuccess1={isSuccess1} setShowMessages={setShowMessages} />
                                                        }
                                                    </div>

                                                    
                                                    {
                                                        <div className="p-0">
                                                            <TransitionGroup>
                                                                {
                                                                    isMorePages === 1 && (
                                                                    <CSSTransition key="step1" timeout={300} classNames={direction === 'forward' ? 'slide' : 'slide-back'}>
                                                                        <div className="main_menu height_full">
                                                                            <div className="container-fluid text-center pt-20">
                                                                                <p style={{ fontSize:'14px', color:'#222', fontWeight:'600', lineHeight:'19px' }}>Welcome! You can start your transactions by registering your phone and creating your PIN.</p>
                                                                            </div>
                                                        
                                                                                
                                                                            <div className="poster-section_ all_btns mt-10 pb-40">

                                                                                {/* {
                                                                                isDisabledButton && (
                                                                                    <>
                                                                                        <div className="tooltip1">
                                                                                            <span className="tooltip-text1">Click here to register your phone number</span>
                                                                                        </div>
                                                                                        <div className="overlays1"></div>
                                                                                    </>
                                                                                )
                                                                                } */}

                                                                                <div className="inner_house p-3 pb-0">
                                                                                    <button onClick={() => openDivHandler('buy_airtime', 'airtime')}>Buy Airtime</button>

                                                                                    <button onClick={() => openDivHandler('buy_data', 'data')}>Buy Data</button>

                                                                                    <button className="active defaults" onClick={() => openDivHandler('transfer_uba', 'transfer to UBA')}>Transfer</button>

                                                                                    <button onClick={() => openDivHandler('pay_bills', 'pay bills')}>Pay Bills</button>

                                                                                    <button onClick={() => openDivHandler('acct_balance', 'account balance')}>Check Balance</button>

                                                                                    {/* <button ref={createPinRef} disabled={disableCreatePin} style={{ opacity: disableCreatePin ? '0.5' : 1 }} onClick={() => openDivHandler('create_pin', 'create pin')}>{disableCreatePin ? "PIN Created" : "Create PIN"}</button> */}


                                                                                    <button onClick={() => openDivHandler('enquiries', 'enquiries')}>Enquiries / Support</button>

                                                                                    <button onClick={toggleSettings}>Next <i className={`fa ${isCounter ? "fa-chevron-right" : "fa-chevron-left"}`}></i></button>


                                                                                </div>
                                                                                

                                                                                <div className="pb-30">
                                                                                    <Link 
                                                                                        style={{ position:'relative', zIndex:991 }}
                                                                                        className="back_btn" 
                                                                                        to="/"
                                                                                    >
                                                                                        <img className="img-fluid arrow" src="../assets/images/arrow.svg" alt="back button" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CSSTransition>

                                                                )}

                                                                {
                                                                    isMorePages === 2 && (
                                                                    <CSSTransition key="step2" timeout={300} classNames={direction === 'forward' ? 'slide' : 'slide-back'}>
                                                                        <div className="height_full mt-4">
                                                                            <div className="container-fluid text-center">
                                                                                <p style={{ fontSize:'18px', color:'#222', fontWeight:'700', lineHeight:'19px' }}>MORE MENU</p>
                                                                            </div>
                                                        
                                                                            <div className="poster-section_ all_btns pb-40">
                                                                                <div className="inner_house p-3 pt-4 pb-0">

                                                                                    <button onClick={() => openDivHandler('click_credit', 'click credit')}>Click Credit</button>

                                                                                    <button onClick={() => openDivHandler('acct_opening', 'Account Opening')}>Account Opening</button>

                                                                                    <button onClick={() => openDivHandler('cardless', 'cardless')}>Cardless Withdrawal</button>

                                                                                    <button onClick={() => openDivHandler('change_pin', '')}>Reset Transaction PIN</button>

                                                                                    <button onClick={() => openDivHandler('transaction_limit', '')}>Transaction Limit</button>

                                                                                    <button onClick={toggleSettings}>Next <i className={`fa ${isCounter ? "fa-chevron-right" : "fa-chevron-left"}`}></i></button>

                                                                                    <button onClick={logout}>Logout</button>
                                                                                </div>

                                                                                <div className="pb-30">
                                                                                    <Link 
                                                                                        className="back_btn active" 
                                                                                        onClick={() => goToMain(1)}
                                                                                    >
                                                                                        <img className="img-fluid arrow" src="../assets/images/arrow.svg" alt="back button" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CSSTransition>
                                                                    
                                                                )}

                                                                {
                                                                    isMorePages === 3 && (
                                                                    <CSSTransition key="step3" timeout={300} classNames={direction === 'forward' ? 'slide' : 'slide-back'}>
                                                                        <div className="height_full mt-4">
                                                                            <div className="container-fluid text-center">
                                                                                <p style={{ fontSize:'18px', color:'#222', fontWeight:'700', lineHeight:'19px' }}>MORE MENU</p>
                                                                            </div>
                                                        
                                                                            <div className="poster-section_ all_btns pb-40">
                                                                                <div className="inner_house p-3 pt-4 pb-0">

                                                                                    <button onClick={() => openDivHandler('pay_attitude', 'PayAttitude')}>PayAttitude</button>

                                                                                    <button onClick={() => openDivHandler('mini_statement', 'Mini Statement')}>Mini Statement</button>

                                                                                    <button onClick={() => openDivHandler('generate_otp', 'Generate OTP')}>Generate OTP</button>

                                                                                    <button onClick={() => openDivHandler('bank_services', 'Bank Services')}>Bank Services</button>

                                                                                    <button onClick={() => openDivHandler('lifestyle', 'Lifestyle')}>Lifestyle</button>

                                                                                    <button onClick={logout}>Logout</button>
                                                                                </div>

                                                                                <div className="pb-30">
                                                                                    <Link 
                                                                                        className="back_btn active" 
                                                                                        onClick={() => goToMain(2)}
                                                                                    >
                                                                                        <img className="img-fluid arrow" src="../assets/images/arrow.svg" alt="back button" />
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CSSTransition>
                                                                )}
                                                            </TransitionGroup>
                                                        </div>
                                                    }

                                                </div>
                                            }
                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}