import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import { SHA512 } from "crypto-js";
import UserAccounts from "./UserAccounts";

export default function DataDiv(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, setSteps, inputDatas, getNetworks, setGetNetworks, setValue, setInputDatas, mtnPhoneCodes, airtelPhoneCodes, gloPhoneCodes, etisalatPhoneCodes, cusDetails, setShowErrMessages } = props;

    const [toggleNtwk, setToggleNtwk] = useState(false);
    const [ntwkChange, setNtwkChange] = useState('Mtn');
    const [ntwkLoader, setNtwkLoader] = useState(true);
    const [ntwkDataBundles, setNtwkDataBundles] = useState([]);
    const [dataBunds, setDataBunds] = useState([]);
    const [activeItem, setActiveItem] = useState('monthly');
    

    const goBack = (steps) => {
        const backSteps = steps - 1;
        setToggleNtwk(false);
        if(backSteps === 3){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }


    let packageName = "";
    let packageDuration = "";
    let amounts = "0.00";

    if (inputDatas && inputDatas.data_package) {
        const data_package = inputDatas.data_package;
        const packageArray = data_package.split("||");
        if (packageArray.length === 3) {
            packageName = packageArray[0];
            packageDuration = packageArray[1];
            amounts = packageArray[2];
        }
    }

    const toggleNtwkPort = () => {
        setToggleNtwk(!toggleNtwk);
    }

    const handleChangeNetwork = (e) => {
        const values = e.target.value;
        if(values !== ""){
            setGetNetworks(values);
        }
    }


    const buyData = async (data) => {
        setInputDatas(data);

        const phonePrefix = data.phone.substring(0, 4);
        let network = "Unknown";
        if (mtnPhoneCodes.includes(phonePrefix)) {
            network = "MTN";
        } else if (airtelPhoneCodes.includes(phonePrefix)) {
            network = "Airtel";
        } else if (gloPhoneCodes.includes(phonePrefix)) {
            network = "Globacom";
        } else if (etisalatPhoneCodes.includes(phonePrefix)) {
            network = "9mobile";
        }
        data.refs1 = refs1;

        const whatNetwork = cusDetails.phone === data.phone ? getNetworks : network;
        setGetNetworks(whatNetwork ? whatNetwork : network);

        if(codes === "buy_data"){
            if(steps === 1){
                setSteps(2);
                setValue("pin", "");
            }else if(steps === 2){
                setSteps(3);
                setValue("pin", "");
            }else if(steps === 3){
                setSteps(4);
                setValue("pin", "");
            }else if(steps === 4){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(5);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    
    const getDataBundle = async (ntwkChange) => {
        const datas = {
            'network': ntwkChange
        }
        try {
            const response = await fetch(API_ROUTES.DATA_BUNDLE, {
                method: "POST",
                headers: { ...headers },
                body: JSON.stringify(datas),
            });
            const responseJSON = await response.json();
            if(responseJSON.status.toString() === "200"){

                const grouped = groupedData(responseJSON.product);
                setNtwkDataBundles(Object.entries(grouped));
                setNtwkLoader(false);
                // console.log(responseJSON.product);

            }else{
                setNtwkLoader(true);
            }
        } catch (error) {
            if(error.response){
                console.log(error.response);
            }
        }
    }



    const groupedData = (dataArrs) => {
        return dataArrs.reduce((acc, bundle) => {
            const validity = bundle.validity.toLowerCase();
            const daysMatch = validity.match(/\b(\d+)\s*day[s]?\b/);
            const hrsMatch = validity.match(/\b(\d+)\s*hr(?:s)?\b/);

            // const validityText = data.validity;
            // const startIndex = validity.lastIndexOf('(') + 1;
            // const endIndex = validity.lastIndexOf(')');
            // const validityPeriod = validity.substring(startIndex, endIndex);

            // console.log("validityPeriod");
            // console.log(validityPeriod);

            if (hrsMatch) {
                const hours = parseInt(hrsMatch[1]);
                if (hours <= 24) {
                acc.daily.push(bundle);
                return acc;
                }
            } else if (validity.includes('24 hours')) { 
                acc.daily.push(bundle);
                return acc;
            }
        
            if (daysMatch) {
                const days = parseInt(daysMatch[1]);
                if (days < 7) {
                    acc.daily.push(bundle);
                } else if (days >= 7 && days <= 21) {
                    acc.weekly.push(bundle);
                } else if (days <= 30) {
                    acc.monthly.push(bundle);
                } else if (days > 30 && days <= 180) {
                    acc.biMonthly.push(bundle);
                } else if (days <= 365) {
                    acc.yearly.push(bundle);
                } else {
                    acc.otherBundles.push(bundle);
                }
            } else {
                    acc.otherBundles.push(bundle);
            }
      
          return acc;
        }, { daily: [], weekly: [], monthly: [], yearly: [], biMonthly: [], otherBundles: [] });
    }


    const fetchDataBundle = async (duration) => {

        setActiveItem(duration);
        
        const filteredData = ntwkDataBundles.find(([key, _]) => key === duration);
        setNtwkLoader(true);
        setDataBunds([]);

        setTimeout(() => {
            if (filteredData) {
                setDataBunds(filteredData[1]);
                setNtwkLoader(false);
            }
        }, 200);
    }


    const handleNtwkChange = (e) => {
        const values = e.target.value;
        setNtwkChange(values);
        setGetNetworks(values);

        if(values !== ""){
            setNtwkLoader(true);
            getDataBundle(values);
        }
    }

    useEffect(() => {
        getDataBundle(ntwkChange);
        
    }, []);

    useEffect(() => {
        fetchDataBundle('monthly');
    }, [ntwkDataBundles]);

    


    return (
        <form className="mt--5" onSubmit={handleSubmit(buyData)}>
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <div className="row radios mt--5 mb--20">
                            <div className="col-6 text-end">
                                <input {...register("selfOthers")} type="radio" value="||Self" name="selfOthers" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'self', setValue)}  />
                                <span>Self</span>
                            </div>
                            <div className="col-6 text-left">
                                <input {...register("selfOthers")} type="radio" value="||Others" name="selfOthers" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'others', setValue)}  />
                                <span>Others</span>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-4 pr-0">
                                <select className="left_border_r" style={{ opacity: isLoading ? '1' : 1 }}
                                    {...register("network", { 
                                        required: "Please select a network"
                                    })}
                                    onChange={handleNtwkChange}
                                >
                                    <option value="">-----</option>
                                    <option value="Mtn" selected>Mtn</option>
                                    <option value="Airtel">Airtel</option>
                                    <option value="Glo">Glo</option>
                                    <option value="9mobile">9mobile</option>
                                </select>
                            </div>
                            <div className="col-8 pl-0">
                                <input type="number" className="right_border_r not_amount focusInput" placeholder="Enter phone number"  autoComplete="off"
                                    {...register("phone", { 
                                        required: "Please enter your phone number",
                                        pattern: {
                                            value: /^\d{11}$/,
                                            message: "Phone number must be at least 11 digits"
                                        }
                                    })}
                                />
                            </div>
                        </div>
                        {errors.phone && <label className="errors">{errors.phone?.message}</label>}


                        <div className="container">
                            <div className="overfloat_width">
                                {
                                    ntwkLoader ? <div style={{ textAlign:'center', fontSize:'13px', color:'#666' }}>Loading durations...</div> : 
                                    <div className="overfloat_width1">
                                        <div className="row">
                                            <div className={`col_2 ${activeItem === 'daily' ? 'actives' : ''}`} onClick={() => fetchDataBundle('daily')}>Daily</div>
                                            <div className={`col_2 ${activeItem === 'weekly' ? 'actives' : ''}`} onClick={() => fetchDataBundle('weekly')}>Weekly</div>
                                            <div className={`col_2 ${activeItem === 'monthly' ? 'actives' : ''}`} onClick={() => fetchDataBundle('monthly')}>Monthly</div>
                                            <div className={`col_2 ${activeItem === 'biMonthly' ? 'actives' : ''}`} onClick={() => fetchDataBundle('biMonthly')}>BiMonth</div>
                                            <div className={`col_2 ${activeItem === 'yearly' ? 'actives' : ''}`} onClick={() => fetchDataBundle('yearly')}>Yearly</div>
                                            <div className={`col_2 ${activeItem === 'otherBundles' ? 'actives' : ''}`} onClick={() => fetchDataBundle('otherBundles')}>XtraData</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        
                        {ntwkLoader && <div style={{ position:'absolute', left:'45%', marginTop:'-26px' }}><LoadingSpinner /></div>}
                        <select style={{ opacity: ntwkLoader ? '0.5' : 1 }}
                            {...register("data_package", { 
                                required: "Please data package"
                            })}
                        >
                            <option value="">{ntwkLoader ? "Loading Package..." : "-Select Package-"}</option>
                            {dataBunds.length !==0 && dataBunds.map((product, index) => {

                                // const startIndex = product.validity.lastIndexOf('(') + 1;
                                // const endIndex = validity.lastIndexOf(')');
                                // const validityPeriod = validity.substring(startIndex, endIndex);

                                // let newValidity = product.validity + " - ";
                                // if(product.allowance === product.validity){
                                //     newValidity = "";
                                // }

                                return (
                                    <option key={index} value={`${product.allowance}||${product.validity}||${product.price}`}>
                                        {product.allowance} - &#8358;{parseFloat(product.price).toLocaleString()} - {product.validity}
                                    </option>
                                )
                            })}
                        </select>
                        {errors.data_package && (<div className="errors">{errors.data_package?.message}</div>)}
                    </>
                }

                {
                    steps === 2 &&
                    <UserAccounts register={register} errors={errors} />
                }

                {
                    steps === 3 &&
                    <div className="enterPin">
                        <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>

                        <div className="row c_details mb-20">
                            <div className="col-4 text-end mb-1">
                                <div><b>Reference:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ textTransform:'capitalize' }}>#{refs1}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Account:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.account_type ? inputDatas.account_type.split('||')[0] : null}</div>
                                <div style={{ fontSize:'13px', marginTop:'-2px', color:'#065884' }}>{inputDatas.account_type ? inputDatas.account_type.split('||')[1] : null}</div>
                            </div>
                            
                            <div className="col-4 text-end mb-1">
                                <div><b>Recepient:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.phone} ({inputDatas.selfOthers.split('||')[1]})</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Network:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{getNetworks}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Package:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{packageName} - {packageDuration}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(amounts).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 4 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 5 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3"><b>{inputDatas.phone}</b> have successfully been credited with data of {packageName} - {packageDuration} for <b>&#8358;{parseFloat(amounts).toLocaleString()}</b>. Thank you for using UBA Smart Sell</p>
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }

                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps === 2
                        ?
                        "Confirm"
                        :
                        steps === 5 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 5) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}