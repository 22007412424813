import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";


export default function ChangePin(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone } = props;

    const [togglePassword, setTogglePassword] = useState(false);

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    // console.log(inputDatas);


    const handleChangePin = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }

        console.log(customData);
        // return;
        if(codes === "change_pin"){

            if(steps === 1){
                setSteps(2);
                console.log(1);
            }else if(steps === 222){

                const hashedData = SHA512(data.old_pin).toString();
                const hashedData1 = SHA512(hashedData).toString();

                if(storedDetlsString.pin === hashedData1){
                    setSteps(3);
                    
                }else{
                    setShowErrMessages("error||" + "Incorrect old Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
                
            }else if(steps === 2){ // was 3
                const customData1 = {
                    phone: customData.phones,
                    ...data
                }
                try {
                    const response = await fetch(API_ROUTES.SEND_OTP, {
                        method: "POST",
                        headers: { ...headers },
                        body: JSON.stringify(customData1),
                    });
                    const responseJSON = await response.json();
                    if(responseJSON.status.toString() === "200"){
                        setSteps(3);
                    }else{
                        if(responseJSON.status.toString() === "500"){
                            setShowErrMessages("error||" + responseJSON.required_fields);
                            setTimeout(() => {
                                setShowErrMessages(false);
                            }, 3000);
                            return;
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }
                } catch (error) {
                    setShowErrMessages("error||" + error.message);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
                console.log(2);


            }else if(steps === 3){ // was 4
                console.log(3);
                setSteps(4);
                
                
            }else if(steps === 4){ // was 5
                // setSteps(5);
                console.log(4);
                const customData1 = {
                    phone: customData.phones,
                    ...data
                }

                try {
                    const response = await fetch(API_ROUTES.CHANGE_PIN, {
                        method: "POST",
                        headers: { ...headers },
                        body: JSON.stringify(customData1),
                    });
                    const responseJSON = await response.json();

                    // console.log("responseJSON");
                    // console.log(responseJSON);

                    if(responseJSON.status.toString() === "200"){
                        const storedDetls = JSON.parse(localStorage.getItem('stored_details'));
                        storedDetls.pin = responseJSON.data;
                        localStorage.setItem('stored_details', JSON.stringify(storedDetls));
                        setCusDetails(storedDetls);

                        setSteps(5);
                    }else{
                        if(responseJSON.status.toString() === "500"){
                            setShowErrMessages("error||" + responseJSON.required_fields);
                            return;
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }
                } catch (error) {
                    setShowErrMessages("error||" + error.message);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }

            }else if(steps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setIsSuccess1(true);
            setShowMessages(true);
        } else {
            setIsSuccess1(false);
        }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(handleChangePin)}>
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'16px' }}>Choose one to reset PIN</p>

                        <div className="row radios mt-20 mb--20">
                            <div className="col-12 text-left">
                                <input id="acct_num" {...register("resetTypes")} type="radio" value="||acct_num" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'acct_num', setValue)}  />
                                <label htmlFor="acct_num">By Account Number</label>
                            </div>
                            
                            <div className="col-12 text-left mt--20">
                                <input id="prepaid_card" {...register("resetTypes")} type="radio" value="||prepaid_card" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'prepaid_card', setValue)}  />
                                <label htmlFor="prepaid_card">By Prepaid Card</label>
                            </div>
                        </div>
                    </>
                }

                {
                    steps === 2 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        {/* acct_num */}
                        {/* <input type={togglePassword ? 'text' : 'password'} className="passwordInput focusInput" placeholder="Enter Old PIN"  autoComplete="off"
                            {...register("old_pin", { 
                                required: "Please enter your old Pin"
                            })}
                            style={{ fontSize: togglePassword ? '19px' : '25px' }}
                        />
                        <label className="positionEye" onClick={() => setTogglePassword(!togglePassword)}><i className={`fa fa-${togglePassword ? 'eye-slash' : 'eye'}`}></i></label>
                        {errors.old_pin && <label className="errors">{errors.old_pin?.message}</label>} */}


                        <input type="number" className="not_amount"  
                        placeholder={`Enter ${inputDatas.resetTypes === "||prepaid_card" ? "Physical Card" : "Account Number"}`}
                        autoComplete="off"
                            {...register("no_digits", { 
                                // required: "Please enter account number",
                                required: `Please enter ${inputDatas.resetTypes === "||prepaid_card" ? "physical card" : "account number"}`,
                                pattern: {
                                    value: /^\d{10}$/,
                                    // message: "Account number must be at least 10 digits"
                                    message: `${inputDatas.resetTypes === "||prepaid_card" ? "Physical card" : "Account number"}  must be at least 10 digits`,
                                }
                            })}
                            // onChange={handleInputChange}
                        />
                        {errors.no_digits && <label className="errors">{errors.no_digits?.message}</label>}


                        <input type={togglePassword ? 'text' : 'password'} className="passwordInput" placeholder="Enter New PIN"  autoComplete="off"
                            {...register("new_pin", { 
                                required: "Please enter your new Pin",
                                pattern: {
                                    value: /^\d{4}$/,
                                    message: "Pin must be 4 digits"
                                }
                            })}
                            style={{ fontSize: togglePassword ? '19px' : '25px' }}
                        />
                        {errors.new_pin && <label className="errors">{errors.new_pin?.message}</label>}
                        

                        <input type={togglePassword ? 'text' : 'password'} className="passwordInput" placeholder="Confirm PIN"  autoComplete="off"
                            {...register("confirm_pin", { 
                                required: "Confirm PIN is required",
                                validate: (value) => {
                                    const new_pin1 = getValues('new_pin');
                                    return value === new_pin1 ? true : "Your PINs do not match"
                                }
                            })}
                            style={{ fontSize: togglePassword ? '19px' : '25px' }}
                        />
                        {errors.confirm_pin && <label className="errors">{errors.confirm_pin?.message}</label>}
                    </>
                }

                {
                    steps === 3 &&
                    <>
                        <div className="text-center pt-10 pb-10">
                            <h4 style={{ fontWeight:'700', fontSize:'16px' }}>Proceed to change your transaction PIN</h4>
                            <p className="mt-10" style={{ lineHeight:'18px' }}>You are to use this PIN for every transaction carried out here. This can also be changed any time</p>
                        </div>
                    </>
                }

                {
                    steps === 4 &&
                    <>
                        <div className="text-center pt-5">
                            {/* <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p> */}

                            <p className="mb-10" style={{ fontWeight:'600', fontSize:'14px', lineHeight:'20px' }}>Enter the OTP code below from your mobile phone.</p>

                            <input type="password" className="passwordInput focusInput" placeholder="Enter OTP"  autoComplete="off"
                                {...register("code", { 
                                    required: "Please enter your OTP",
                                    pattern: {
                                        value: /^\d{6}$/,
                                        message: "OTP must be 6 digits"
                                    }
                                })}
                            />
                            {errors.pin && <label className="errors">{errors.pin?.message}</label>}
                        </div>
                    </>
                }

                {
                    steps === 5 &&
                    <div className="successDiv text-center pt-10 mb-20" style_="display: none;">
                        <p className="mb-3" style={{ lineHeight:'18px', fontWeight:'600', fontSize:'15px' }}>Your Transaction PIN has been updated successfully!</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }
                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {(steps === 1 || steps === 2)
                        ?
                        "Proceed"
                        :
                        steps === 4
                        ?
                        "Submit"
                        :
                        steps === 5 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 5) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}