import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import UserAccounts from "./UserAccounts";

export default function AirtimeDiv(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, setSteps, inputDatas, getNetworks, setGetNetworks, setValue, cusDetails, setInputDatas, mtnPhoneCodes, airtelPhoneCodes, gloPhoneCodes, etisalatPhoneCodes, setShowErrMessages, isPlaceholder } = props;

    const [toggleNtwk, setToggleNtwk] = useState(false);

    // console.log("cusDetails");
    // console.log(cusDetails);

    
    const goBack = (steps) => {
        const backSteps = steps - 1;
        setToggleNtwk(false);
        if(backSteps === 3){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    
    const toggleNtwkPort = () => {
        setToggleNtwk(!toggleNtwk);
    }

    const handleChangeNetwork = (e) => {
        const values = e.target.value;
        if(values !== ""){
            setGetNetworks(values);
        }
    }


    const buyAirtime = async (data) => {
        setInputDatas(data);
        // console.log(data);

        const phonePrefix = data.phone.substring(0, 4);
        let network = "Unknown";
        if (mtnPhoneCodes.includes(phonePrefix)) {
            network = "MTN";
        } else if (airtelPhoneCodes.includes(phonePrefix)) {
            network = "Airtel";
        } else if (gloPhoneCodes.includes(phonePrefix)) {
            network = "Globacom";
        } else if (etisalatPhoneCodes.includes(phonePrefix)) {
            network = "9mobile";
        }
        data.refs1 = refs1;

        const whatNetwork = cusDetails.phone === data.phone ? getNetworks : network;
        setGetNetworks(whatNetwork ? whatNetwork : network);

        if(codes === "buy_airtime"){
            if(steps === 1){
                setSteps(2);
                // console.log(2);
                setValue("pin", "");
            }else if(steps === 2){
                setSteps(3);
                // console.log(3);
                setValue("pin", "");

            }else if(steps === 3){
                setSteps(4);
                // console.log(4);
                setValue("pin", "");

            }else if(steps === 4){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(5);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }



    return (
        <form className="mt--5" onSubmit={handleSubmit(buyAirtime)}>
            <div className="mainDiv">

                {
                    steps === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <div className="row radios mt--5 mb--20">
                            <div className="col-6 text-end">
                                <input {...register("selfOthers")} type="radio" value="||Self" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'self', setValue)}  />
                                <span>Self</span>
                            </div>
                            <div className="col-6 text-left">
                                <input {...register("selfOthers")} type="radio" value="||Others" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'others', setValue)}  />
                                <span>Others</span>
                            </div>
                        </div>


                        <input type="number" 
                        // defaultValue={phoneInput} 
                        className="not_amount focusInput" placeholder={isPlaceholder} autoComplete="off"
                            {...register("phone", { 
                                required: `Phone number is required`,
                                pattern: {
                                    value: /^\d{11}$/,
                                    message: "Phone number must be at least 11 digits"
                                }
                            })}
                        />
                        {errors.phone && <label className="errors">{errors.phone?.message}</label>}

                        
                        <input type="number" className="form-controls" placeholder="Type your amount here"  autoComplete="off"
                            {...register("amount", { 
                                required: "Please enter your amount",
                                min: {
                                    value: 50,
                                    message: "Amount should start from N50"
                                },
                                max: {
                                    value: 10000,
                                    message: "Amount should stop at N10,000"
                                },
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                    </>
                }

                {
                    steps === 2 &&
                    <UserAccounts register={register} errors={errors} />
                }

                {
                    steps === 3 &&
                    <div className="enterPin">
                        <p className="mb-2" style={{ fontSize: '20px', fontWeight: 600 }}>Confirm Your Details</p>

                        <div className="row c_details mb-20">
                            <div className="col-4 text-end mb-1">
                                <div><b>Reference:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ textTransform:'capitalize' }}>#{refs1}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Account:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.account_type ? inputDatas.account_type.split('||')[0] : null}</div>
                                <div style={{ fontSize:'13px', marginTop:'-2px', color:'#065884' }}>{inputDatas.account_type ? inputDatas.account_type.split('||')[1] : null}</div>
                            </div>
                            
                            <div className="col-4 text-end mb-1">
                                <div><b>Recepient:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.phone} ({inputDatas.selfOthers ? inputDatas.selfOthers.split('||')[1] : null})</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Network:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div className="row">
                                    <div className="col-4">
                                        <div>{getNetworks}</div>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            {!toggleNtwk && <span onClick={toggleNtwkPort} style={{ fontSize:'13px', color:'#065884', cursor:'pointer', marginTop:'3px' }}>Change Network</span>}
                                            
                                            {
                                                toggleNtwk &&
                                                <>
                                                    <div className="col-8">
                                                        <select className="changeNtwkDropdown" onChange={(e) => handleChangeNetwork(e)}>
                                                            <option value="">-Select-</option>
                                                            <option value="MTN">MTN</option>
                                                            <option value="Airtel">Airtel</option>
                                                            <option value="Globacom">Globacom</option>
                                                            <option value="9mobile">9mobile</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-4 pl-0 close_ntwk" onClick={toggleNtwkPort}>
                                                        <span>x</span>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-8">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>N{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 4 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps === 5 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3"><b>{inputDatas.phone}</b> have successfully been credited with <b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b> airtime. Thank you for using UBA Smart Sell</p>
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps === 2
                        ?
                        "Confirm"
                        :
                        steps === 5 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 5) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}