const API_URL = 'https://smartsell.approot.ng';
const SERVER_ENDPOINT = 'https://api.daneizotradeafrica.com';
// const SERVER_ENDPOINT = 'http://127.0.0.1:5552';


export const API_ROUTES = {
    CREATE_PHONE:               `${SERVER_ENDPOINT}/api/auths/create-phone`,
    VERIFY_OTP:                 `${SERVER_ENDPOINT}/api/auths/verify-otp`,
    SEND_OTP:                   `${SERVER_ENDPOINT}/api/auths/send-otp`,
    GET_AUTH_DETAILS:           `${SERVER_ENDPOINT}/api/auths/auth-details`,
    CREATE_PIN:                 `${SERVER_ENDPOINT}/api/auths/create-pin`,
    CHANGE_PIN:                 `${SERVER_ENDPOINT}/api/auths/change-pin`,
    UPDATE_LIMIT:               `${SERVER_ENDPOINT}/api/auths/update-limit`,
    DATA_BUNDLE:                `${SERVER_ENDPOINT}/api/auths/data-Bundle`,
    VERIFY_PIN:                 `${SERVER_ENDPOINT}/api/auths/verify-pin`,
    
    

    API_URL,
    SERVER_ENDPOINT,
}