import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import CableTV from "./CableTV";
import Electricity from "./Electricity";
import Education from "./Education";

export default function PayBills(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, billTypes, setInputDatas, setValue, setShowErrMessages, cusDetails, titleName, setTitleName, reset } = props;

    const [isSuccess, setIsSuccess] = useState(false);
    const [isAmt, setIsAmt] = useState(0);
    const [isMatch, setIsMatch] = useState(false);
    const [innerSteps, setInnerSteps] = useState(1);
    const submitBtn = useRef(null);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
        }
    }

    const goBack = (steps) => {
        const backSteps = steps - 1;
        setSteps(backSteps);
    }

    const [filteredDatas, setFilteredDatas] = useState(billTypes);

    const handleSearchChange = (event) => {
            const inputValue = event.target.value.toLowerCase();
            const filtered = billTypes.filter((data1) =>
            data1.name.toLowerCase().includes(inputValue)
        );
        setFilteredDatas(filtered);
    }



    const handleBills = async (data) => {
        // console.log(data);
        setInputDatas(data);
        // console.log(steps);

        const names = data.bills_type.split('||')[1];

        // console.log(names);

        if(codes === "pay_bills"){
            if(steps === 1){
                setTitleName(names.toUpperCase());
                setSteps(2);
                // console.log(2);

                const containsMatchCable = data && data.bills_type && data.bills_type.toLowerCase().includes("cable");
                const containsMatchEle = data && data.bills_type && data.bills_type.toLowerCase().includes("electricity");
                const containsMatchEdu = data && data.bills_type && data.bills_type.toLowerCase().includes("education");
                const containsMatchBet = data && data.bills_type && data.bills_type.toLowerCase().includes("betting");
                const containsMatchToll = data && data.bills_type && data.bills_type.toLowerCase().includes("tolls");
                const containsMatchAirline = data && data.bills_type && data.bills_type.toLowerCase().includes("airlines");
                const containsMatchWater = data && data.bills_type && data.bills_type.toLowerCase().includes("water");
                const containsMatchWaste = data && data.bills_type && data.bills_type.toLowerCase().includes("waste");
                const containsMatchInternet = data && data.bills_type && data.bills_type.toLowerCase().includes("internet");
                const containsMatchChurch = data && data.bills_type && data.bills_type.toLowerCase().includes("churches");

                if(containsMatchCable) setInnerSteps(1);
                if(containsMatchEle) setInnerSteps(2);
                if(containsMatchEdu) setInnerSteps(3);
                if(containsMatchBet) setInnerSteps(4);
                if(containsMatchToll) setInnerSteps(5);
                if(containsMatchAirline) setInnerSteps(6);
                if(containsMatchWater) setInnerSteps(7);
                if(containsMatchWaste) setInnerSteps(8);
                if(containsMatchInternet) setInnerSteps(9);
                if(containsMatchChurch) setInnerSteps(10);
                

            }else if(steps === 2){
                setSteps(3);
                // console.log(3);
            }else if(steps === 3){
                setSteps(4);
                // console.log(4);
            }else if(steps === 4){
                setSteps(5);
                // console.log(5);
                
            }else if(steps === 5){
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(6);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
                
            }else if(steps === 6){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleDoubleClick = () => {
        if (submitBtn.current) {
            submitBtn.current.click();
        }
    }




    return (
        
        <div className="mainDiv pt-5">

            <input type="hidden" autoComplete="off" value={refs1}
                {...register("refs1")}
            />

            {
                steps === 1 && (
                    <>
                        <form className="mt--5" onSubmit={handleSubmit(handleBills)}>
                            <div>
                                <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'17px' }}>Select Category</p>
                                <div className="row radios1 mt--5 mb-20">

                                    <div className="col-12">
                                        <input type="text" className="not_amount focusInput pt-10 pb-10" placeholder="Search a category" autoComplete="off"
                                            style={{ fontSize:'14px', textTransform:'capitalize', height:'40px' }}
                                            onChange={handleSearchChange}
                                        />
                                    </div>

                                    <div className="col-12 inner_div_scroll pt-5 pb-10">
                                        {filteredDatas.map(bill_type => (
                                            <div key={bill_type.id} className="row mb-10" onDoubleClick={handleDoubleClick}>
                                                <div className="col-2 pr-0">
                                                    <input 
                                                        className="mt-3"
                                                        style={{ position: 'relative', zIndex:9 }} type="radio" value={`${bill_type.id}||${bill_type.name}`} id={bill_type.id} name="bills_type" autoComplete="off" 
                                                        onChange={(e) => handleCheckboxClick(e, 'bills_type', setValue)}

                                                        {...register("bills_type", { 
                                                            required: "Please select a bill type",
                                                        })}
                                                    /> 
                                                </div>
                                                <div className="col-10 pl-0">
                                                    <div className="div_bg">
                                                        <label htmlFor={bill_type.id}>{bill_type.name}</label>
                                                        <label className="fonts" htmlFor={bill_type.id}>{bill_type.description}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="all_btns mt--5 text-center">
                                <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }} ref={submitBtn}>
                                    {steps === 2
                                    ?
                                    "Next"
                                    :
                                    steps === 4
                                    ?
                                    "Proceed"
                                    :
                                    steps === 6 ? "Go To Main Menu" : "Next"
                                    }
                                </button>
                            </div>

                            <div className="mt--5 text-center">
                                {
                                    (steps > 1 && steps < 6) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                                }
                                <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                            </div>
                        </form>
                    </>
                )
            }

            {
                steps === 2 &&
                <>
                    {
                        innerSteps === 1 && 
                        <CableTV closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} register={register} handleSubmit={handleSubmit} isSubmitting={isSubmitting} isValid={isValid} errors={errors} isLoading={isLoading} steps={1} setSteps={setSteps} codes={codes} setShowErrMessages={setShowErrMessages} inputDatas={inputDatas} setInputDatas={setInputDatas} titleName={titleName} setTitleName={setTitleName} cusDetails={cusDetails} setValue={setValue} reset={reset} />
                    }

                    {
                        innerSteps === 2 && 
                        <Electricity closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} register={register} handleSubmit={handleSubmit} isSubmitting={isSubmitting} isValid={isValid} errors={errors} isLoading={isLoading} steps={1} setSteps={setSteps} codes={codes} setShowErrMessages={setShowErrMessages} inputDatas={inputDatas} setInputDatas={setInputDatas} titleName={titleName} setTitleName={setTitleName} cusDetails={cusDetails} />
                    }

                    {
                        innerSteps === 3 && 
                        <Education closeHandler={closeHandler} cmdNext={cmdNext} titles={titles} register={register} handleSubmit={handleSubmit} isSubmitting={isSubmitting} isValid={isValid} errors={errors} isLoading={isLoading} steps={1} setSteps={setSteps} codes={codes} setShowErrMessages={setShowErrMessages} inputDatas={inputDatas} setInputDatas={setInputDatas} titleName={titleName} setTitleName={setTitleName} cusDetails={cusDetails} />
                    }

                    {
                        (innerSteps === 4 || innerSteps === 5 || innerSteps === 6 || innerSteps === 7 || innerSteps === 8 || innerSteps === 9 || innerSteps === 10) && 
                        <div className="text-center pt-20 pb-20">Coming Soon</div>
                    }
                </>
            }
        </div>
    );
}