import { BrowserRouter } from 'react-router-dom';
import './App.css';
// import RouterComponent from './Components/Routes/RouterComponent';
import { useEffect, useState } from 'react';
import RouterComponent from './components/routes/RouterComponent';


function App() {

  useEffect(() => {
    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          await navigator.serviceWorker.register('/sw.js');
        } catch (error) {
          console.error('Service worker registration failed:', error);
        }
      }
    };
    registerServiceWorker();
    return () => {
      // Unregister service worker if needed
      // Example: navigator.serviceWorker.unregister();
    };
  }, []);

  return (
    <BrowserRouter>
      <RouterComponent />
    </BrowserRouter>
  );
}

export default App;
