import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import SHA512 from 'crypto-js/sha512';
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";
import EnterPin from "./EnterPin";
import UserAccounts from "./UserAccounts";


export default function BankServices(props){
    const { closeHandler, cmdNext, titles, register, handleSubmit, errors, isSubmitting, isValid, isLoading, getValues, steps, setSteps, cusPhone, codes, setShowErrMessages, setCusDetails, handleCheckboxClick, setValue, setIsSuccess1, showMessages, setShowMessages, setInputDatas, inputDatas, setCusPhone, cusDetails, isSuccess1 } = props;

    const [isCardBlocked, setIsCardBlocked] = useState(false);
    const [isTransBlocked, setIsTransBlocked] = useState(false);

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    // console.log(inputDatas);


    const handleFormActn = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const storedDetlsString = JSON.parse(localStorage.getItem('stored_details'));
        setCusPhone(cusPhone || storedDetlsString.phone);
        
        const customData = {
            phones: cusPhone || storedDetlsString.phone,
            ...data
        }

        console.log(customData);

        // return;
        if(codes === "bank_services"){
            if(steps === 1){
                setSteps(2);
                // console.log(2);
                setValue("pin", "");
            }else if(steps === 2){
                console.log(3);

                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();
                if(cusDetails.pin === hashedData1){
                    setSteps(3);
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
  
            }else if(steps === 3){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }


    const handleInputChange = (e) => {
        const values = e.target.value;
        // setNtwkChange(values);
        // setGetNetworks(values);

        // if(values !== ""){
        //     setNtwkLoader(true);
        //     getDataBundle(values);
        // }
    }

    const blockCard = () => {
        const userConfirmed = window.confirm(`Proceed to ${isCardBlocked ? 'unblock' : 'block'} your debit card?`);
        
        setShowErrMessages("processing||" + "Your Request is been processed");

        if (userConfirmed) {
            setTimeout(() => {
                setShowErrMessages(false);
            }, 1000);

            setTimeout(() => {
                setIsCardBlocked(!isCardBlocked);
                alert(`Your debit card has been ${isCardBlocked ? 'unblocked' : 'blocked'}!`);
            }, 1200);
        } else {
            console.log('Block operation cancelled');
            setShowErrMessages(false);
        }
    }
    
    const blockTrans = () => {
        const userConfirmed = window.confirm(`Proceed to ${isTransBlocked ? 'unfreeze' : 'freeze'} your online transaction?`);
        
        setShowErrMessages("processing||" + "Your Request is been processed");

        if (userConfirmed) {
            setTimeout(() => {
                setShowErrMessages(false);
            }, 1000);

            setTimeout(() => {
                setIsTransBlocked(!isTransBlocked);
                alert(`Your online transaction has been ${isTransBlocked ? 'unfreezed' : 'freezed'}!`);
            }, 1200);
        } else {
            console.log('Freeze operation cancelled');
        }
    }




    return (
        <form className="mt--5" onSubmit={handleSubmit(handleFormActn)}>
            
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <>
                        <p className="mb-1" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>Bank Services</p>

                        <p className="mb-3" style={{ color:'#222', fontWeight:'500', lineHeight:'20px', fontSize:'14px' }}>{titles}</p>

                        {/* <div className="row radios mt-20 mb--10">
                            <div className="col-12 text-left">
                                <input id="block_card" {...register("b_services")} type="radio" value="||block_card" defaultChecked autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'block_card', setValue)}  />
                                <label htmlFor="block_card">Block Debit Card</label>
                            </div>
                            
                            <div className="col-12 text-left mt--20">
                                <input id="freeze_trans" {...register("b_services")} type="radio" value="||freeze_trans" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'freeze_trans', setValue)}  />
                                <label htmlFor="freeze_trans">Freeze Online Transaction</label>
                            </div>
                        </div> */}
                    </>
                }

                {
                    (steps === 2) &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    (steps === 3) &&
                    <>
                    <p className="mb-1 mt-1" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'18px' }}>Bank Services</p>

                    <p className="mb-3" style={{ color:'#222', fontWeight:'500', lineHeight:'20px', fontSize:'14px' }}>{titles}</p>

                    <div className="all_btns all_btns1 mt-20 mb-20 text-center">
                        <button style={{ opacity: isCardBlocked ? 0.5 : 1 }} className="btn btn-primary" type="button" onClick={blockCard}>
                            {isCardBlocked ? "UnBlock Debit Card" : "Block Debit Card"}
                        </button>

                        <button style={{ opacity: isTransBlocked ? 0.5 : 1 }} className="btn btn-primary" type="button" onClick={blockTrans}>
                            {isTransBlocked ? "UnFreeze Transaction" : "Freeze Online Transaction"}
                        </button>
                    </div>
                    </>
                }
                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {
                        steps === 3 ? "Go To Main Menu" : "Proceed"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 2) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler('')}>Close</span>
                </div>
            </div>
        </form>
    );
}