import React, { useEffect, useState } from "react";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";
import { API_ROUTES } from "../utils/constants";
import { headers } from "../utils/headers";

export default function TransactionLimit(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, setSteps, inputDatas, getNetworks, setValue, setShowErrMessages, cusDetails, setInputDatas, setCusDetails } = props;

    const goBack = (steps) => {
        const backSteps = steps - 1;
        if(backSteps === 2){
            setSteps(steps - 2);
            return;
        }
        setSteps(backSteps);
    }

    const validateAmount = (value) => {
        const amount = parseInt(value);
        if (isNaN(amount)) {
            return "Please enter a valid number";
        }
        if (amount < 1000) {
            return "Daily limit must be at least N1,000";
        }
        if (amount > 10000000) {
            return "Daily limit must not exceed N10,000,000";
        }
        return true; // Return true if validation passes
    }

    const handleTransactionLimit = async (data) => {
        // console.log(data);
        setInputDatas(data);
        const customData = {
            phone: cusDetails.phone,
            ...data
        }

        if(codes === "transaction_limit"){
            if(steps === 1){
                // console.log(2);
                const hashedData = SHA512(data.pin).toString();
                const hashedData1 = SHA512(hashedData).toString();

                if(cusDetails.pin === hashedData1){
                    setSteps(2);
                    
                }else{
                    setShowErrMessages("error||" + "Incorrect Pin entered");
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }

            }else if(steps === 2){
                // setSteps(3);
                // console.log(3);
                // setTimeout(() => {
                //     setValue2('pin', '');
                // }, 30);

                try {
                    const response = await fetch(API_ROUTES.UPDATE_LIMIT, {
                        method: "POST",
                        headers: { ...headers },
                        body: JSON.stringify(customData),
                    });
                    const responseJSON = await response.json();

                    if(responseJSON.status.toString() === "200"){
                        const storedDetls = JSON.parse(localStorage.getItem('stored_details'));
                        storedDetls.transaction_limit = responseJSON.data;
                        localStorage.setItem('stored_details', JSON.stringify(storedDetls));
                        setSteps(3);
                        setCusDetails(storedDetls);
                    }else{
                        if(responseJSON.status.toString() === "500"){
                            setShowErrMessages("error||" + responseJSON.required_fields);
                            setTimeout(() => {
                                setShowErrMessages(false);
                            }, 3000);
                            return;
                        }
                        setShowErrMessages("error||" + responseJSON.message);
                        setTimeout(() => {
                            setShowErrMessages(false);
                        }, 3000);
                    }
                } catch (error) {
                    setShowErrMessages("error||" + error.message);
                    setTimeout(() => {
                        setShowErrMessages(false);
                    }, 3000);
                }
            }else if(steps === 3){
                setSteps(4);
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);   
            }
        }
    }


    return (
        <form className="mt--5" onSubmit={handleSubmit(handleTransactionLimit)}>
            <div className="mainDiv">
                {
                    steps === 1 &&
                    <EnterPin register={register} errors={errors} />
                }
                {
                    steps === 2 &&
                    <>
                        <p className="mb-3 mt-2" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'16px' }}>{titles}</p>

                        <input type="number" defaultValue={cusDetails.transaction_limit} className="form-controls not_amount focusInput" placeholder="Enter transaction limit amount"  autoComplete="off"
                            {...register("trans_limit", { 
                                required: "Please set your transaction limit amount",
                                validate: validateAmount
                            })}
                        />
                        {errors.trans_limit && <label className="errors">{errors.trans_limit?.message}</label>}
                    </>
                }

                {
                    steps === 3 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3">Your daily transaction limit has been set to <b>&#8358;{parseFloat(inputDatas.trans_limit).toLocaleString()}</b>. Thank you for using UBA Smart Sell</p>
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }

                

                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps === 2
                        ?
                        "Set Limit"
                        :
                        steps === 3 ? "Go To Main Menu" : "Next"
                        }
                    </button>

                    {
                        (steps > 1 && steps < 3) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}