import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";
import { SHA512 } from "crypto-js";

export default function Education(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, billTypes, setInputDatas, setValue, setShowErrMessages, cusDetails, titleName, setTitleName } = props;

    const [isSuccess, setIsSuccess] = useState(false);
    const [isAmt, setIsAmt] = useState(0);
    const [steps1, setSteps1] = useState(1);


    const goBack = (steps) => {
        const backSteps = steps1 - 1;
        if(backSteps === 2){
            setSteps1(steps - 2);
            return;
        }
        setSteps1(backSteps);
    }


    let packageName = "";
    let packageDuration = "";
    let amounts = "0.00";

    if (inputDatas && inputDatas.package) {
        const packageType = inputDatas.package;
        const packageArray = packageType.split("||");
        if (packageArray.length >= 2) {
            packageName = packageArray[0];
            // packageDuration = packageArray[1];
            amounts = packageArray[1];
        }
    }


    const handleForms = (data) => {
        // console.log(data);
        setInputDatas(data);
        // return;
        
        if(steps1 === 1){
            setSteps1(2);
            // console.log(2);
        }else if(steps1 === 2){
            setSteps1(3);
            // console.log(3);
        }else if(steps1 === 3){
            // setSteps1(4);
            // console.log(4);

            const hashedData = SHA512(data.pin).toString();
            const hashedData1 = SHA512(hashedData).toString();

            if(cusDetails.pin === hashedData1){
                setSteps1(4);
            }else{
                setShowErrMessages("error||" + "Incorrect Pin entered");
                setTimeout(() => {
                    setShowErrMessages(false);
                }, 3000);
            }
        }else if(steps1 === 4){
            closeHandler(codes);
            setTimeout(() => {
                setSteps1(1);
            }, 300);
        }
    }

    const generateRandomAmount = () => {
        const minAmount = 10;
        const maxAmount = 500;
        const randomStep = Math.floor(Math.random() * (maxAmount - minAmount + 1)) + minAmount;
        return randomStep * 100;
    }

    const handlePackageChange = (e) => {
        const values = e.target.value;
        if(values !== ""){
            setIsAmt(generateRandomAmount());
        }
    }
    



    return (
        <form className="mt--5" onSubmit={handleSubmit(handleForms)}>
            <div className="mainDiv pt-5">

                {/* <p className="mb-3" style={{ color:'#222', fontWeight:700, lineHeight:'20px', fontSize:'17px' }}><b>{titleName}</b></p> */}

                <input type="hidden" autoComplete="off" value={refs1}
                    {...register("refs1")}
                />

                {
                    steps1 === 1 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:700, lineHeight:'20px', fontSize:'17px' }}><b>{titleName}</b></p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <select className=""
                            {...register("edu_type", { 
                                required: "Please select education type"
                            })}
                            // onChange={handleNtwkChange}
                        >
                            <option value="">-Select One-</option>
                            <option value="Universities">Universities</option>
                            <option value="Polytechnics">Polytechnics</option>
                            <option value="Colleges">Colleges</option>
                        </select>
                        {errors.edu_type && <label className="errors">{errors.edu_type?.message}</label>}


                        <select className=""
                            {...register("edu_name", { 
                                required: "Please select a University"
                            })}
                            onChange={handlePackageChange}
                        >
                            <option value="">-Select University-</option>
                            <option value="Mountain Top University">Mountain Top University</option>
                            <option value="University of Jos, Plateau">University of Jos, Plateau</option>
                        </select>
                        {errors.edu_name && <label className="errors">{errors.edu_name?.message}</label>}


                        <input type="number" className="form-controls" placeholder="Type your amount here"  autoComplete="off"
                            {...register("amount", { 
                                required: "Please enter your amount",
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                        
                    </>
                }

                {
                    steps1 === 2 &&
                    <div className="enterPin">
                        <p className="mb-2" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>

                        <div className="row c_details mb-20">
                            <div className="col-4 text-end mb-1">
                                <div><b>Reference:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ textTransform:'capitalize' }}>#{inputDatas.refs1}</div>
                            </div>
                            
                            <div className="col-4 text-end mb-1">
                                <div><b>College:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{(inputDatas.edu_type)}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Name:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div>{inputDatas.edu_name}</div>
                            </div>

                            <div className="col-4 text-end mb-1">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-8 mb-1">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps1 === 3 &&
                    <EnterPin register={register} errors={errors} />
                }

                {
                    steps1 === 4 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3">Your payment of <b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b> was successful. Thank you for using UBA Smart Sell</p>
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting || !isValid} style={{ opacity: (isSubmitting || !isValid) ? '0.5' : 1 }}>
                        {steps1 === 2
                        ?
                        "Confirm"
                        :
                        steps1 === 3
                        ?
                        "Proceed"
                        :
                        steps1 === 4 ? "Go To Main Menu" : "Next"
                        }
                    </button>
                </div>

                <div className="text-center">
                    {
                        (steps1 > 1 && steps1 < 4) && <span className="closeBtn" onClick={() => goBack(steps1)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}