import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";

export default function SavedBeneficiary(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, beneficiaries, setIsTransfer, setIsBeneficiary, setTitles, setCodes, setInputDatas, setBankName, setValue, setIsSuccess1, setAcctNo, setShowMessages } = props;

    const [isSuccess, setIsSuccess] = useState(false);
    const submitBtn = useRef(null);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
        }
    }

    const handleDoubleClick = () => {
        if (submitBtn.current) {
            submitBtn.current.click();
        }
    }

    
    const [filteredDatas, setFilteredDatas] = useState(beneficiaries);

    const handleSearchChange = (event) => {
            const inputValue = event.target.value.toLowerCase();
            const filtered = beneficiaries.filter((data1) =>
            data1.name.toLowerCase().includes(inputValue)
        );
        setFilteredDatas(filtered);
    }


    const goBack = (steps) => {
        const backSteps = steps - 1;

        if(steps === 1){
            setSteps(1);
            setIsBeneficiary(false);
            setIsTransfer(true);
            setTitles("To transfer to UBA, enter amount to be transferred below");

            setCodes('transfer_uba');
            return;
        }
        setSteps(backSteps);
    }


    const getBeneficiary = async (data) => {
        // console.log(data);
        setInputDatas(data);
        
        const get_account_number = data.beneficiary_details;
        const acct_split = get_account_number.split("||")[0];
        const acct_nos = acct_split.split(" (")[0];
        const acct_name = get_account_number.split("||")[1];

        const match = acct_split.match(/\(([^)]+)\)/);
        const extractedBankName = match ? match[1] : '';

        setBankName(`${acct_name} - ${extractedBankName}`);
        // setValue('acct_number', acct_nos);
        setAcctNo(acct_nos);
        setIsSuccess1(false);

        if(codes === "open_beneficiary"){
            if(steps === 1){
                setSteps(codes === "transfer_uba" ? 2 : 1);
                setIsBeneficiary(false);
                setIsTransfer(true);
                setShowMessages(true);
                setTitles("To transfer to UBA, enter amount to be transferred below");
                setCodes('transfer_uba');
                // console.log(2);
            }else if(steps === 2){
                // setSteps(3);
                // console.log(2);
            }else if(steps === 3){
                // setSteps(4);
                // console.log(2);
            }else if(steps === 4){
                // setSteps(5);
                // console.log(2);
            }else if(steps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                    // console.log(2);
                }, 300);
            }
        }
    }




    return (
        <form className="mt--5" onSubmit={handleSubmit(getBeneficiary)}>
            <div className="mainDiv pt-5">
                

                <input type="hidden" autoComplete="off" value={refs1}
                    {...register("refs1")}
                />

                {
                    steps === 1 &&
                    <div>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px', fontSize:'15px' }}>These are your saved beneficiaries</p>
                        <div className="row radios1 mt--5 mb-10">

                            <div className="col-12 mt--5">
                                <input type="text" className="not_amount focusInput pt-15 pb-15" placeholder="Search beneficiary"  autoComplete="off"
                                    style={{ fontSize:'16px', textTransform:'capitalize' }}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            
                            <div className="col-12 inner_div_scroll pt-5 pb-10">
                                {filteredDatas.map(beneficiary => (
                                    <div key={beneficiary.id} className="row mb-2" onDoubleClick={handleDoubleClick}>
                                        <div className="col-2 pr-0">
                                            <input 
                                                className="mt-2"
                                                style={{ position: 'relative', zIndex:9 }} type="radio" value={`${beneficiary.description}||${beneficiary.name}`} id={beneficiary.id} name="beneficiary_details" autoComplete="off" onChange={(e) => handleCheckboxClick(e, '', setValue)}

                                                {...register("beneficiary_details", { 
                                                    required: "Please select a beneficiary",
                                                })}
                                            /> 
                                        </div>
                                        <div className="col-10 pl-0">
                                            <label className="div_bg div_bg1" htmlFor={beneficiary.id}>
                                                <label htmlFor={beneficiary.id}>{beneficiary.name}</label>
                                                <label htmlFor={beneficiary.id} className="fonts">{beneficiary.description}</label>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 2 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <div className="text-center userWallet mb-10"><label>Transfer From: &#8358;40,000</label></div>

                        <input type="number" className="form-controls" placeholder="Type your amount here"  autoComplete="off"
                            {...register("amount", { 
                                required: "Please enter your amount",
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                        <div style={{ marginTop:'-7px', marginBottom:'3px', fontSize:'12px', color:'green', textAlign:'center', fontWeight:'600' }}>Your daily transfer is N5,000</div>

                        <input type="number" className="form-controls not_amount focusInput" placeholder="Enter your transaction code"  autoComplete="off"
                            {...register("trans_code", { 
                                required: "Please enter your transaction code",
                                pattern: {
                                    value: /^\d{6}$/,
                                    message: "Transaction code must be at least 6 digits"
                                }
                            })}
                        />
                        {errors.trans_code && <label className="errors">{errors.trans_code?.message}</label>}
                        
                    </>
                }

                {
                    steps === 3 &&
                    <EnterPin register={register} errors={errors} />
                }
                
                {
                    steps === 322 &&
                    <div className="enterPin">
                        <p className="mb-0 mt--5" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>
                        <p>You are about to make this transaction. Kindly confirm these details below and continue.</p>

                        <div className="row c_details mt-10">
                            <div className="col-5 text-end">
                                <div><b>Account Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>Chinny Anthony</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Account Number:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>{inputDatas.acct_number}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Bank Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600', lineHeight:'18px', fontSize:'13px' }}><b>{inputDatas.banks.split('||')[1]}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 4 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>You have successfully transfered <b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b> to <b>Chinny Anthony</b>. Do you want to add Chinny to your beneficiary list?</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }

                {
                    steps === 5 &&
                    <div className="successDiv text-center mb-15">
                        <p className="mb-3" style={{ lineHeight:'18px' }}>You have successfully added <b>Chinny Anthony</b> to your beneficiary list.<br />Thank you for using UBA Smart Sell.</p>
                        
                        <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                    </div>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={(isSubmitting || !isValid) && !isSuccess} style={{ opacity: ((isSubmitting || !isValid ) && !isSuccess) ? '0.5' : 1 }} ref={submitBtn}>
                        {steps === 2
                        ?
                        "Transfer"
                        :
                        steps === 4
                        ?
                        "Add To Beneficiary List"
                        :
                        steps === 5 ? "Go To Main Menu" : "Next"
                        }
                    </button>
                </div>

                <div className="mt--5 text-center">
                    {
                        (steps >= 1 && steps < 4) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}