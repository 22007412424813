import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import EnterPin from "./EnterPin";

export default function CardlessWithdrawal(props){
    const { closeHandler, cmdNext, titles, codes, refs1, register, handleSubmit, errors, isSubmitting, isValid, handleCheckboxClick, isLoading, steps, listOfBanks, inputDatas, setSteps, withdrawalTypes, setInputDatas, setValue } = props;

    const [isSuccess, setIsSuccess] = useState(false);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d{10}$/.test(inputValue)) {
            setIsSuccess(true);
        } else {
            setIsSuccess(false);
        }
    }

    const goBack = (steps) => {
        const backSteps = steps - 1;
        setSteps(backSteps);
        // console.log(backSteps);
    }

    const cardlessWithdraw = async (data) => {
        // console.log(data);
        setInputDatas(data);
        if(codes === "cardless"){
            if(steps === 1){
                setSteps(2);
            }else if(steps === 2){
                setSteps(3);
            }else if(steps === 3){
                setSteps(4);
            }else if(steps === 4){
                setSteps(5);
            }else if(steps === 5){
                closeHandler(codes);
                setTimeout(() => {
                    setSteps(1);
                }, 300);
            }
        }
    }



    return (
        <form className="mt--5" onSubmit={handleSubmit(cardlessWithdraw)}>
            <div className="mainDiv pt-5">
                

                <input type="hidden" autoComplete="off" value={refs1}
                    {...register("refs1")}
                />

                {
                    steps === 1 &&
                    <div>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>Select Withdrawal Type</p>
                        <div className="row radios1 mt--5 mb-20">
                            <div className="col-12 inner_div_scroll pt-5 pb-10">
                            
                                {withdrawalTypes.map(withdrawal_type => (
                                    <div key={withdrawal_type.id} className="row mb-10">
                                        <div className="col-2 pr-0">
                                            <input 
                                                className="mt-3"
                                                style={{ position: 'relative', zIndex:9 }} type="radio" value={`${withdrawal_type.id}||${withdrawal_type.name}`} id={withdrawal_type.id} name="withdraw_type" autoComplete="off" onChange={(e) => handleCheckboxClick(e, 'withdraw_type', setValue)}

                                                {...register("withdraw_type", { 
                                                    required: "Please select your withdrawal type",
                                                })}
                                            /> 
                                        </div>
                                        <div className="col-10 pl-0">
                                            <div className="div_bg">
                                                <label htmlFor={withdrawal_type.id}>{withdrawal_type.name}</label>
                                                <label className="fonts" htmlFor={withdrawal_type.id}>{withdrawal_type.description}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }

                {
                    steps === 2 &&
                    <>
                        <p className="mb-3" style={{ color:'#222', fontWeight:'600', lineHeight:'20px' }}>{titles}</p>

                        <input type="hidden" autoComplete="off" value={refs1}
                            {...register("refs1")}
                        />

                        <div className="text-center userWallet mb-10"><label>Transfer From: &#8358;40,000</label></div>

                        <input type="number" className="form-controls" placeholder="Type your amount here"  autoComplete="off"
                            {...register("amount", { 
                                required: "Please enter your amount",
                            })}
                        />
                        {errors.amount && <label className="errors">{errors.amount?.message}</label>}
                        <div style={{ marginTop:'-7px', marginBottom:'3px', fontSize:'12px', color:'green', textAlign:'center', fontWeight:'600' }}>Your daily transfer is N5,000</div>

                        <input type="number" className="form-controls not_amount focusInput" placeholder="Enter your transaction code"  autoComplete="off"
                            {...register("trans_code", { 
                                required: "Please enter your transaction code",
                                pattern: {
                                    value: /^\d{6}$/,
                                    message: "Transaction code must be at least 6 digits"
                                }
                            })}
                        />
                        {errors.trans_code && <label className="errors">{errors.trans_code?.message}</label>}
                        
                    </>
                }

                {
                    steps === 3 &&
                    <EnterPin register={register} errors={errors} />
                }
                
                {
                    steps === 4 &&
                    <div className="enterPin">
                        <p className="mb-0 mt--5" style={{ fontSize: '15px', lineHeight:'22px', fontWeight: 600 }}>The withdrawal code will be shown and sent via SMS to the registered phone number</p>

                        <p className="mb-2 mt-10" style={{ fontSize: '17px', lineHeight:'23px', fontWeight: 600 }}>Withdrawal Code</p>


                        <div className="row text-center">
                            <div className="col-12">
                                <input 
                                    className="mt-1 passwordInput focusInput"
                                    style={{ position: 'relative', zIndex:9, background:'none', border:'1px solid #999', color:'#d93737' }} type="text" autoComplete="off"
                                    placeholder="_ _ _ _ _ _ _ _ _ _"
                                    {...register("withdraw_code", { 
                                        required: "Please enter your withdrawal code",
                                        pattern: {
                                            value: /^\d{10}$/,
                                            message: "Pin must be 10 digits"
                                        }
                                    })}
                                /> 
                            </div>
                        </div>

                        <div className="row mt-1 mb-3 text-center">
                            <div className="col-12">
                                <div style={{ fontSize:'14px' }}>Expiry Time:</div>
                            </div>
                            <div className="col-12">
                                <div style={{ color:'#333', fontWeight:'600', fontSize:'14px' }}><b>March 3rd, 2024 @ 12:51pm</b></div>
                            </div>
                        </div>
                    </div>
                }

                {/* {
                    steps === 5 &&
                    <div className="enterPin">
                        <p className="mb-0 mt--5" style={{ fontSize: '22px', fontWeight: 600 }}>Confirm Your Details</p>
                        <p>You are about to make this transaction. Kindly confirm these details below and continue.</p>

                        <div className="row c_details mt-10">
                            <div className="col-5 text-end">
                                <div><b>Account Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>Chinny Anthony</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Account Number:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>{inputDatas.acct_number}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Bank Name:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600', lineHeight:'18px', fontSize:'13px' }}><b>{inputDatas.banks.split('||')[1]}</b></div>
                            </div>
                            <div className="col-5 text-end">
                                <div><b>Amount:</b></div>
                            </div>
                            <div className="col-7">
                                <div style={{ color:'#333', fontWeight:'600' }}><b>&#8358;{parseFloat(inputDatas.amount).toLocaleString()}</b></div>
                            </div>
                        </div>
                    </div>
                } */}

                {
                    steps === 5 &&
                    <>
                        <div className="successDiv text-center mb-15">
                            <img className="img-fluid" src="../assets/images/success-icon.svg" alt="arrow" />
                        </div>

                        <div className="text-left mb-15">
                            <p className="mb-3 mt-2 text-left" style={{ lineHeight:'20px', fontSize:'16px', fontWeight:'600' }}>To go any ATM, Select cardless withdrawal/pay code cash-out, enter withdrawal code, transaction code and your card Pin</p>

                            <p className="mb-4 text-left" style={{ lineHeight:'20px', fontSize:'16px', fontWeight:'600' }}>Enter amount and withdraw funds successfully</p>
                        </div>
                    </>
                }


                <div className="all_btns mt-10 text-center">
                    <button className="btn btn-primary" type="submit" disabled={(isSubmitting || !isValid) && !isSuccess} style={{ opacity: ((isSubmitting || !isValid ) && !isSuccess) ? '0.5' : 1 }}>
                        {steps === 2
                        ?
                        "Transfer"
                        :
                        steps === 4
                        ?
                        "Done"
                        :
                        steps === 5 ? "Go To Main Menu" : "Next"
                        }
                    </button>
                </div>

                <div className="mt--5 text-center">
                    {
                        (steps > 1 && steps < 4) && <span className="closeBtn" onClick={() => goBack(steps)}>{'<'} Back</span>
                    }
                    <span className="closeBtn" onClick={() => closeHandler(codes)}>Close</span>
                </div>
            </div>
        </form>
    );
}