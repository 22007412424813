import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
// import MainScreen from "../MainScreen";
import { BrowserRouter, Navigate, Route, Routes, useNavigate, Redirect } from 'react-router-dom';
import MainScreen from "../MainScreen";
import MainScreen2 from "../MainScreen2";
// import PrivateRoutes from "../../utils/PrivateRoutes";
// import MainScreen from "../MainScreen";
// import Index from "../Dashboards/Index";
// import $ from 'jquery';
// import Swal from "sweetalert2";
// import 'perfect-scrollbar/css/perfect-scrollbar.css';
// import { useLocation } from 'react-router-dom';



export default function RouterComponent(){


    return (
        
        <Routes>
            
            <Route path="/" element={<MainScreen />} />
            <Route path="/dashboard" element={<MainScreen2 />} />

            {/* <Route path="*" element={<NotFound />} />

            <Route path="/dashboard/" element={
                <Index />
            }/>


            <Route path="/add-money/" element={
                <Wallet />
            }/> */}

            
            
        </Routes>
    );
}